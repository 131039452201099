<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Enviar para DP</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalSendRD"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4">
        <span>👋 Olá, antes de enviar para o DP, preencha os campos abaixo!</span>
      </div>
      <Separator
        label="informações do aprovado"
        class="mt-3"
      />

      <v-text-field
        v-model="hiredPersonName"
        class="mt-6"
        label="Nome"
        outlined
        dense
      />

      <div class="line mt-0">
        <v-text-field
          v-model="hiredPersonCpf"
          class=""
          label="CPF"
          type="number"
          outlined
          dense
        />
        <v-dialog
          ref="dialogDateTime"
          v-model="showCalendar"
          :return-value.sync="inconsistencyDate"
          width="400px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedInconsistencyDate"
              :append-icon="icons.mdiCalendar"
              :rules="[rules.required]"
              label="Previsão de Admissão"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="inconsistencyDate"
            width="400px"
            locale="pt-BR"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="showCalendar = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialogDateTime.save(inconsistencyDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>

      <div class="container-center">
        <v-btn
          color="success"
          class="ml-4"
          @click="handleSendDP"
        >
          <span v-if="!loadingSendDP">ENVIAR PARA DP</span>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>

import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiCalendar, mdiClose } from '@mdi/js'

export default {
  components: {
    Separator,
  },

  mixins: [formatters, utilsMixin],

  props: {
    data: {
      type: [String, Object],
      required: true,
    },
    closeModalrddp: { type: Function, default: () => {} },
  },

  data() {
    return {
      inconsistencyDate: '',
      showCalendar: false,
      loadingSendDP: false,
      icons: {
        mdiClose,
        mdiCalendar,
      },
      listClass: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
      listLevels: ['1', '2', '3', '4', '5', '6', '7', '8'],
      salary: '',
      className: '',
      level: '',
      atr: '',
      comments: '',
      hiredPersonName: '',
      hiredPersonCpf: '',
      expectedStartDate: '',
    }
  },

  computed: {
    computedInconsistencyDate: {
      get() {
        return this.formatDate(this.inconsistencyDate)
      },

      set() {},
    },
  },

  methods: {
    handleCloseModalSendRD() {
      this.closeModal()
    },

    async handleSendDP() {
      this.loadingSendDP = true

      console.log(body)

      const body = {
        status: 'AGUARDANDO DP',
        hired_person_name: this.hiredPersonName,
        hired_person_cpf: this.hiredPersonCpf,
        expected_start_date: this.inconsistencyDate,
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Requisição enviada!',
          text: 'A requisição foi enviada para o departamento Pessoal!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSendDP = false

        this.updatedTable()

        this.closeModalrddp()

        this.closeModal()
      }
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container-center{
  display: flex;
  justify-content: center;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}
</style>
