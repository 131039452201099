<template>
  <v-skeleton-loader
    v-if="skeleton"
    type="article, actions"
  />
  <v-card v-else>
    <div class="head-modal">
      <div></div>

      <h3 style="font-weight: 600; font-size: 18px">
        Desclassificar candidato
      </h3>

      <v-icon
        medium
        class="me-2 pr-3"
        @click="closeDisqualifyModal"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>
    <h3
      class="mt-4 text-center mx-10 mb-7"
      style="font-size: 14px; font-weight: 400;"
    >
      👋 Olá! Antes de realizar a desclassificação, por favor, descreva o motivo abaixo
    </h3>

    <v-form
      ref="form"
      class="mx-5"
    >
      <v-textarea
        v-model="reasonToDesqualify"
        outlined
        label="Descrição do motivo"
        :rules="[rules.required]"
      ></v-textarea>
      <div class="d-flex align-center justify-center pb-6">
        <v-btn
          color="error"
          outlined
          @click="handleDisqualifyApproved"
        >
          DESCLASSIFICAR
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiClose } from '@mdi/js'

export default {
  name: 'DisqualifyApproved',
  mixins: [formatters, utilsMixin, messages],
  props: {
    data: {
      type: [String, Object],
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      skeleton: false,
      reasonToDesqualify: '',
      mainData: [],
    }
  },
  created() {
    this.handleGetMainData()
  },
  methods: {
    async handleGetMainData() {
      try {
        const response = await axiosIns.get(`/api/v1/personnel_request/employee_request/edit/${this.data.employee_request_id}`)
        this.mainData = response.data.data
      } catch (error) {
        console.log(error)
      }
    },
    closeDisqualifyModal() {
      this.$emit('close')
    },
    async handleDisqualifyApproved() {
      const isValid = this.$refs.form.validate()
      if (!isValid) return
      const body = {
        status: 'Desclassificado',
        reason_for_disqualification: this.reasonToDesqualify,
      }
      const body2 = {
        status: 'EM ANDAMENTO',
        alert: {
          vote: this.mainData.alert?.vote || null,
          disqualification: true,
        },
      }
      try {
        await axiosIns.put(`/api/v1/personnel_request/classification_history/update/${this.data.id}`, body)

        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.employee_request_id}`, body2)
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Desclassificação feita com sucesso!',
          showConfirmButton: false,
          timer: 5000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao desclassificar!',
          showConfirmButton: false,
          timer: 5000,
        })
      } finally {
        this.$emit('updateResults')
        this.$emit('atualiza')
        this.closeDisqualifyModal()
      }
    },
  },
}
</script>

<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 20px;
}
</style>
