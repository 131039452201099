<template>
  <div class="container-general">
    <img src="../../../../../assets/alert.svg" />

    <h2>Tem certeza?</h2>

    <span>Ao clicar em sim, o processo será cancelado</span>
    <span>e não poderá ser mais editado.</span>

    <div class="container-actions">
      <v-btn
        color="error"
        class=""
      >
        Não
      </v-btn>

      <v-btn
        color="success"
        class=""
      >
        Sim
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mdiClose } from '@mdi/js'

export default {
  components: {
  },

  data() {
    return {
      showModalCancel: false,
      icons: {
        mdiClose,
      },
    }
  },

  methods: {
    handleOpenModalCancel() {
      this.showModalCancel = true
    },
  },
}
</script>
<style scoped>
.container-general{
  background: #fff;
  padding: 30px;
  width: 400px;
  height: 300px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2{
    color: #545454
  }
  span{
    color: #545454
  }
}

.container-actions{
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
