import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"px-9 py-9"},[_c('div',{staticClass:"head-modal"},[_c('div'),_c('span',[_vm._v("Parecer do Líder")]),_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":_vm.handleCloseModalCanceled}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c('div',{staticClass:"py-4",staticStyle:{"text-align":"center"}},[_c('span',[_vm._v("👋 Olá, por favor, insira o seu parecer, em seguida, encaminhe para o DP")])]),_c('Separator',{staticClass:"mt-3",attrs:{"label":"Informações"}}),_c(VTextarea,{staticClass:"mt-6",attrs:{"label":"Descreva o parecer","outlined":""},model:{value:(_vm.leaderObservations),callback:function ($$v) {_vm.leaderObservations=$$v},expression:"leaderObservations"}}),_c('div',{staticClass:"container-center"},[_c(VBtn,{attrs:{"color":"success"},on:{"click":_vm.handleOpenModalCancelConfirm}},[(!_vm.loadingCancel)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"5px"}},[_c('span',[_vm._v("ENVIAR PARA DP")]),_c(VIcon,{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiShare)+" ")])],1):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1)],1),_c(VDialog,{attrs:{"width":"400px"},model:{value:(_vm.showModalCancelConfirm),callback:function ($$v) {_vm.showModalCancelConfirm=$$v},expression:"showModalCancelConfirm"}},[_c('ModalCancelConfirm',{on:{"close":function($event){_vm.showModalCancelConfirm = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }