<template>
  <div v-if="leadersData.length > 0">
    <Separator
      label="Líderes que validaram a abertura do processo"
      class="mt-6"
    />
    <fieldset
      class="my-6"
      style="border-radius: 5px; border-width: 1px; border-color: #5C5471;"
    >
      <legend
        style="margin-left: 10px;"
        class="px-4"
      >
        Líderes
      </legend>
      <v-card class="p-4">
        <div
          v-for="item in failedOrApproved"
          :key="item.user_name"
          class="pb-3 isNotExpanded"
          :class="{'isExpanded': item.isExpand}"
        >
          <div
            v-if="item.vote === 'Reprovado' || item.vote === 'Aprovado'"
            class="d-flex align-center justify-space-between mx-0 pt-2 pb-2"
            :class="{'isExpandedInside': item.isExpand, 'isNotExpandedInside': !item.isExpand}"
          >
            <div class="d-flex align-center">
              <v-tooltip
                v-if="leadersVotesDiverge(item)"
                color="orange"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="position: absolute; z-index: 10; margin-left: -15px;"
                    v-bind="attrs"
                    color="orange"
                    size="20"
                    class="pb-6"
                    v-on="on"
                  >
                    {{ icons.mdiExclamationThick }}
                  </v-icon>
                </template>
                <span>Importante</span>
              </v-tooltip>
              <v-avatar
                size="30"
                color="error"
                class="v-avatar-light-bg success--text"
              >
                <v-img
                  v-if="item.user_image"
                  :src="item.base64"
                ></v-img>
                <span
                  v-else
                  class="v-avatar-light-bg success--text"
                >
                  {{ getInitials(item) }}
                </span>
              </v-avatar>
              <div
                class="ml-2 d-flex align-start"
                style="gap: 10px;"
              >
                <div>
                  <h3 style="font-weight: 500; font-size: 12px;">
                    {{ item.user_name }}
                  </h3>
                  <div
                    class="d-flex align-center mt-1"
                    style="gap: 9px;"
                  >
                    <v-icon size="16">
                      {{ icons.mdiCalendarBlankOutline }}
                    </v-icon>
                    <h3 style="font-weight: 500; font-size: 12px">
                      {{ dateFormat(item.updated_at) }}
                    </h3>
                  </div>
                </div>
                <div
                  style="padding: 3px 10px; border-radius: 50px;"
                  class="d-flex align-center justify-center"
                  :class="{'approved': item.vote === 'Aprovado', 'failed': item.vote === 'Reprovado'}"
                >
                  <span style="font-weight: 600; font-size: 12px;">{{ item.vote }}</span>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column"
              style="gap: 10px;"
            >
              <v-tooltip
                v-if="item.vote === 'Reprovado'"
                top
                color="gray"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="gray"
                    icon
                    v-bind="attrs"
                    @click="item.isExpand = !item.isExpand"
                    v-on="on"
                  >
                    <v-icon color="gray">
                      {{ icons.mdiChatProcessingOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Motivo</span>
              </v-tooltip>
            </div>
          </div>
          <div
            v-if="item.vote === 'Reprovado' && item.isExpand"
            class=" mx-5"
          >
            <h3
              style="font-weight: 500; font-size: 14px;"
              class="pb-5"
            >
              Motivo da reprovação:
            </h3>
            <h3 style="font-weight: 500; font-size: 14px;">
              {{ item.description }}
            </h3>
          </div>
        </div>
      </v-card>
    </fieldset>
  </div>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiChatProcessingOutline, mdiExclamationThick } from '@mdi/js'

export default {
  user_name: 'FieldsetApprovalTL',

  components: { Separator },

  mixins: [formatters, messages],
  props: {
    data: {
      type: Object,
      required: true,
    },
    profile: { type: String, default: '' },
  },
  data() {
    return {
      leadersData: [],
      icons: { mdiChatProcessingOutline, mdiExclamationThick },
    }
  },
  computed: {
    failedOrApproved() {
      return this.leadersData.filter(item => item.vote === 'Reprovado' || item.vote === 'Aprovado')
    },
  },
  created() {
    this.handleGetData()
  },
  methods: {
    leadersVotesDiverge(item) {
      if (!this.data.alert) {
        return false
      }

      return this.data.alert.vote && this.data.status === 'AGUARDANDO RD' && this.profile === 'Diretor' && item.vote === 'Reprovado'
    },
    getInitials(user) {
      console.log(user)

      if (!user || !user.user_name) {
        return '-'
      }
      const names = user.user_name.split(' ')
      if (names.length === 1) {
        return names[0].charAt(0).toUpperCase()
      }
      const firstInitial = names[0].charAt(0).toUpperCase()
      const lastInitial = names[names.length - 1].charAt(0).toUpperCase()

      return firstInitial + lastInitial
    },
    async handleGetData() {
      const bodyManagerId1 = {
        employee_request_id: this.data.id,
        user_id: this.data.manager_1_id,
      }
      const bodyManagerId2 = {
        employee_request_id: this.data.id,
        user_id: this.data.manager_2_id,
      }

      if (!this.data.manager_2_id) {
        try {
          const response = await axiosIns.post('/api/v1/personnel_request/employee_request_user/edit', bodyManagerId1)

          this.leadersData = response.data?.data?.map(item => ({ ...item, isExpand: false })) || []
        } catch (error) {
          console.error(error)
        }
      } else {
        try {
          // Primeira requisição
          const response1 = await axiosIns.post('/api/v1/personnel_request/employee_request_user/edit', bodyManagerId1)
          const leadersData1 = response1.data?.data?.map(item => ({ ...item, isExpand: false })) || []

          // Segunda requisição
          const response2 = await axiosIns.post('/api/v1/personnel_request/employee_request_user/edit', bodyManagerId2)
          const leadersData2 = response2.data?.data?.map(item => ({ ...item, isExpand: false })) || []

          this.leadersData = [...leadersData1, ...leadersData2]
        } catch (error) {
          console.error(error)
        }
      }
    },
  },
}
</script>

<style scoped>
.isExpanded{
  border: 1px solid #FF4C51;
  margin: 10px 10px;
  padding: 0px;
  border-radius: 5px;
  transition: ease;
  transition-duration: 0.4s;
}
.isNotExpanded{
  transition: ease;
  transition-duration: 0.2s;
}
.isExpandedInside{
  margin: 0px 0px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
}
.isNotExpandedInside{
  padding-left: 28px;
  padding-right: 28px;
}
.approved{
  background: #56CA001A;
  color: #56CA00;
}
.failed{
  background: #FF4C511A;
  color: #FF4C51;
}
</style>
