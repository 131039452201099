<template>
  <v-skeleton-loader
    v-if="skeleton"
    type="article"
  />
  <v-card
    v-else
    class="px-9 py-9"
  >
    <div class="head-modal">
      <div></div>

      <div
        class="d-flex align-center"
        style="gap: 8px;"
      >
        <img
          src="../../../../../assets/mdiChartArc.svg"
          alt="###"
        />
        <h3 style="font-weight: 500; font-size: 18px;">
          Painel de Validação
        </h3>
      </div>

      <v-icon
        medium
        class="me-2"
        @click="handleCloseModal"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>

    <h3
      class="mt-4 text-center"
      style="font-size: 14px; font-weight: 400;"
    >
      👋 Olá, líder! Nesta etapa, você será responsável por decidir sobre a aprovação desta requisição.
    </h3>

    <fieldset
      class="mt-6"
      style="border-radius: 5px;"
    >
      <legend
        style="margin-left: 10px;"
        class="px-4"
      >
        Líderes
      </legend>
      <v-card class="p-4">
        <div
          v-for="item in waitingApproval"
          :key="item.user_name"
          class="pb-3"
        >
          <div
            v-if="item.vote === 'Aguardando aprovação'"
            class="d-flex align-center justify-space-between px-7 pt-2"
          >
            <div
              class="d-flex align-center"
            >
              <v-avatar
                size="30"
                color="error"
                class="v-avatar-light-bg success--text"
              >
                <v-img
                  v-if="item.user_image"
                  :src="item.base64"
                ></v-img>
                <span
                  v-else
                  class="v-avatar-light-bg success--text"
                >
                  {{ getInitials(item) }}
                </span>
              </v-avatar>
              <div class="ml-2">
                <h3 style="font-weight: 500; font-size: 12px;">
                  {{ item.user_name }}
                </h3>
                <div
                  style="background: #FFB4001A; padding: 3px 10px; border-radius: 50px; color: #FFB400; max-width: fit-content;"
                  class="d-flex align-center justify-center"
                >
                  <span style="font-weight: 600; font-size: 12px;">{{ item.vote }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="userProfile.id === item.user_id"
              class="d-flex"
              style="gap: 10px;"
            >
              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="handleOpenDisapprovalModal"
                  >
                    <v-icon color="error">
                      {{ icons.mdiThumbDown }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Reprovar</span>
              </v-tooltip>
              <v-tooltip
                top
                color="success"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="handleLeaderApprove"
                  >
                    <v-icon color="success">
                      {{ icons.mdiThumbUp }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Aprovar</span>
              </v-tooltip>
            </div>
          </div>
          <v-dialog
            v-model="isModalLeaderDisapproveOpen"
            width="545"
            persistent
          >
            <ModalLeaderDisapproval
              :key="item.user_name"
              :profile="userProfile"
              :data="item"
              @updateVotes="handleGetData"
              @close="isModalLeaderDisapproveOpen = false"
              @closeMainModal="handleCloseAll"
              @updateTableIndex="handleUpdate"
            >
            </ModalLeaderDisapproval>
          </v-dialog>
        </div>
        <div
          v-for="item in failedOrApproved"
          :key="item.user_name"
          class="pb-3 isNotExpanded"
          :class="{'isExpanded': item.isExpand}"
        >
          <div
            v-if="item.vote === 'Reprovado' || item.vote === 'Aprovado'"
            class="d-flex align-center justify-space-between px-7 mx-0 pt-2 pb-2"
            :class="{'isExpandedInside': item.isExpand}"
          >
            <div class="d-flex align-center">
              <v-avatar
                size="30"
                color="error"
                class="v-avatar-light-bg success--text"
              >
                <v-img
                  v-if="item.user_image"
                  :src="item.base64"
                ></v-img>
                <span
                  v-else
                  class="v-avatar-light-bg success--text"
                >
                  {{ getInitials(item) }}
                </span>
              </v-avatar>
              <div
                class="ml-2 d-flex align-start"
                style="gap: 10px;"
              >
                <div>
                  <h3 style="font-weight: 500; font-size: 12px;">
                    {{ item.user_name }}
                  </h3>
                  <div
                    class="d-flex align-center mt-1"
                    style="gap: 9px;"
                  >
                    <v-icon size="16">
                      {{ icons.mdiCalendarBlankOutline }}
                    </v-icon>
                    <h3 style="font-weight: 500; font-size: 12px">
                      {{ dateFormat(item.updated_at) }}
                    </h3>
                  </div>
                </div>
                <div
                  style="padding: 3px 10px; border-radius: 50px; max-width: fit-content"
                  class="d-flex align-center justify-center"
                  :class="{'approved': item.vote === 'Aprovado', 'failed': item.vote === 'Reprovado'}"
                >
                  <span style="font-weight: 600; font-size: 12px;">{{ item.vote }}</span>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column"
              style="gap: 10px;"
            >
              <v-tooltip
                v-if="item.vote === 'Reprovado'"
                top
                color="gray"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="gray"
                    icon
                    v-bind="attrs"
                    @click="item.isExpand = !item.isExpand"
                    v-on="on"
                  >
                    <v-icon color="gray">
                      {{ icons.mdiChatProcessingOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Motivo</span>
              </v-tooltip>
            </div>
          </div>
          <div
            v-if="item.vote === 'Reprovado' && item.isExpand"
            class=" mx-5"
          >
            <h3
              style="font-weight: 500; font-size: 14px;"
              class="pb-5"
            >
              Motivo da reprovação:
            </h3>
            <h3 style="font-weight: 500; font-size: 14px;">
              {{ item.description }}
            </h3>
          </div>
        </div>
      </v-card>
    </fieldset>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCalendarBlankOutline,
  mdiChatProcessingOutline,
  mdiClose, mdiThumbDown, mdiThumbUp,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalLeaderDisapproval from './ModalLeaderDisapproval.vue'

export default {
  name: 'ModalLeaderApproval',
  components: { ModalLeaderDisapproval },

  mixins: [formatters, messages],
  props: {
    data: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      skeleton: false,
      expandReason: false,
      isModalLeaderDisapproveOpen: false,
      userProfile: {},
      icons: {
        mdiChatProcessingOutline,
        mdiClose,
        mdiThumbUp,
        mdiThumbDown,
        mdiCalendarBlankOutline,
      },
      leadersData: [],
    }
  },
  computed: {
    waitingApproval() {
      return this.leadersData.filter(item => item.vote === 'Aguardando aprovação')
    },
    failedOrApproved() {
      return this.leadersData.filter(item => item.vote === 'Reprovado' || item.vote === 'Aprovado')
    },
  },
  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })

    this.handleGetData()
  },
  methods: {
    getInitials(user) {
      console.log(user)

      if (!user || !user.user_name) {
        return '-'
      }
      const names = user.user_name.split(' ')
      if (names.length === 1) {
        return names[0].charAt(0).toUpperCase()
      }
      const firstInitial = names[0].charAt(0).toUpperCase()
      const lastInitial = names[names.length - 1].charAt(0).toUpperCase()

      return firstInitial + lastInitial
    },
    handleUpdate() {
      this.$emit('updateTable')
    },
    handleOpenDisapprovalModal() {
      this.isModalLeaderDisapproveOpen = true
    },
    handleCloseModal() {
      this.$emit('close')
    },
    handleCloseAll() {
      this.$emit('closeAll')
    },
    async handleGetData() {
      const bodyManagerId1 = {
        employee_request_id: this.data.id,
        user_id: this.data.manager_1_id,
      }
      const bodyManagerId2 = {
        employee_request_id: this.data.id,
        user_id: this.data.manager_2_id,
      }

      if (!this.data.manager_2_id) {
        try {
          const response = await axiosIns.post('/api/v1/personnel_request/employee_request_user/edit', bodyManagerId1)

          this.leadersData = response.data?.data?.map(item => ({ ...item, isExpand: false })) || []
        } catch (error) {
          console.error(error)
        }
      } else {
        try {
          // Primeira requisição
          const response1 = await axiosIns.post('/api/v1/personnel_request/employee_request_user/edit', bodyManagerId1)
          const leadersData1 = response1.data?.data?.map(item => ({ ...item, isExpand: false })) || []

          // Segunda requisição
          const response2 = await axiosIns.post('/api/v1/personnel_request/employee_request_user/edit', bodyManagerId2)
          const leadersData2 = response2.data?.data?.map(item => ({ ...item, isExpand: false })) || []
          this.leadersData = [...leadersData1, ...leadersData2]
        } catch (error) {
          console.error(error)
        }
      }
    },
    async handleLeaderApprove() {
      const body = {
        employee_request_id: this.data.id,
        user_id: this.userProfile.id,
        vote: 'Aprovado',
      }

      try {
        await this.postRegister(
          '/api/v1/personnel_request/employee_request_user/store',
          body,
          'Deseja aprovar a solicitação?',
          'Essa ação aprovará a solicitação e não poderá ser desfeita.',
        )
      } catch (error) {
        console.log(error)
      } finally {
        this.handleGetData()
        this.handleUpdate()
        this.$emit('close')
        this.$emit('closeAll')
      }
    },

  },
}
</script>

<style scoped>
.approved{
  background: #56CA001A;
  color: #56CA00;
}
.failed{
  background: #FF4C511A;
  color: #FF4C51;
}
.head-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.isExpanded{
  border: 1px solid #FF4C51;
  margin: 10px 10px;
  padding: 0px;
  border-radius: 5px;
  transition: ease;
  transition-duration: 0.4s;
}
.isExpandedInside{

  margin: 0px 0px !important;
  padding-left: 18px !important;
}
.isNotExpanded{
  transition: ease;
  transition-duration: 0.2s;
}
</style>
