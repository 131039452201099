<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Cancelar Requisição de Pessoal</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalCanceled"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4">
        <span>👋 Olá antes de cancelar, descreva um motivo a baixo!</span>
      </div>
      <Separator
        label="Informações de Cancelamento"
        class="mt-6"
      />

      <v-textarea
        v-model="cancellationDescription"
        class="mt-6"
        label="Motivo do Cancelamento"
        outlined
      />

      <div class="container-center">
        <v-btn
          color="error"
          class="ml-4"
          @click="handleOpenModalCancelConfirm"
        >
          <div
            v-if="!loadingCancel"
            class="d-flex align-center"
            style="gap: 5px"
          >
            <span>CANCELAR</span>
            <v-icon>{{ icons.mdiCancel }}</v-icon>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card>

    <v-dialog
      v-model="showModalCancelConfirm"
      width="400px"
    >
      <ModalCancelConfirm
        @close="showModalCancelConfirm = false"
      ></ModalCancelConfirm>
    </v-dialog>
  </div>
</template>
<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiCancel, mdiClose } from '@mdi/js'
import ModalCancelConfirm from './ModalCancelConfirm.vue'

export default {
  components: {
    ModalCancelConfirm, Separator,
  },
  mixins: [formatters, utilsMixin],

  props: {
    data: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      loadingCancel: false,
      showModalCancelConfirm: false,
      cancellationDescription: '',
      icons: {
        mdiClose, mdiCancel,
      },
    }
  },

  methods: {
    handleCloseModalCanceled() {
      this.closeModal()
    },

    async handleOpenModalCancelConfirm() {
      this.loadingCancel = true

      const body = {
        status: 'CANCELADO',
        cancellation_description: this.cancellationDescription,
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Requisição cancelada!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao cancelar requisição!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingCancel = false

        this.updatedTable()
        this.closeModal()
      }
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container-center{
  display: flex;
  justify-content: center;
}
</style>
