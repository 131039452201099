<template>
  <v-skeleton-loader
    v-if="loadingHistory"
    type="article, actions"
  ></v-skeleton-loader>
  <div v-else>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Requisição de Pessoal</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalCanceled"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          class="tabs-just"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <ApplicantInfo
            :applicant-name="applicantName"
            :applicant-sector="applicantSector"
            :applicant-store="applicantStore"
          />
          <Separator
            label="Justificativa"
            class="mt-6"
          />

          <div class="line">
            <v-text-field
              v-model="data.contract_types"
              class=""
              label="Tipo de Contrato"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="data.request_reason"
              class=""
              label="Motivo da Requisição"
              outlined
              dense
              disabled
            />
          </div>

          <v-text-field
            v-if="data.request_reason === 'SUBSTITUIÇÃO'"
            class=""
            label="Funcionário Substituído"
            outlined
            dense
            disabled
          />

          <v-textarea
            v-model="data.request_reason_description"
            label="Descrição do motivo"
            outlined
            disabled
          />
          <Separator
            label="Dados da vaga"
            class="mt-0 mb-8"
          />

          <v-text-field
            v-model="data.occupation.name"
            class="mt-5"
            label="Função"
            outlined
            dense
            disabled
          />

          <v-text-field
            v-model="data.sector.name"
            class=""
            label="Setor"
            outlined
            dense
            disabled
          />
          <div
            style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 25px; height: 30px;"
          >
            <div>
              <p>A função terá a responsabilidade de vender acessórios?</p>

              <v-radio-group
                v-model="radioValue"
                row
                disabled
              >
                <v-radio
                  label="Sim"
                  value="SIM"
                  color="#9C43FA"
                />

                <v-radio
                  label="Não"
                  value="NÃO"
                  color="#9C43FA"
                />
              </v-radio-group>
            </div>
          </div>

          <div class="line mt-0">
            <v-text-field
              v-model="data.age"
              class=""
              label="Idade"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="data.sex"
              class=""
              label="Sexo"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.education_level"
              class=""
              label="Nível de escolaridade"
              outlined
              dense
              disabled
            />
          </div>

          <v-textarea
            v-model="data.vacancy_requirements"
            label="Requisitos da Vaga"
            outlined
            disabled
          />

          <v-textarea
            v-model="data.main_activities"
            label="Principais Atividades"
            outlined
            disabled
          />
          <FieldsetApprovalTL :data="data" />
        </v-tab-item>
        <v-tab-item>
          <Separator
            label="Dados da vaga"
            class="mt-6"
          />
          <v-text-field
            v-model="data.occupation.name"
            class="mt-5"
            label="Função"
            outlined
            dense
            disabled
          />

          <v-text-field
            v-model="data.sector.name"
            class=""
            label="Setor"
            outlined
            dense
            disabled
          />

          <div class="line mt-0">
            <v-text-field
              v-model="data.salary_value"
              class=""
              label="Salário"
              outlined
              dense
              prefix="R$"
              disabled
            />

            <v-text-field
              v-model="data.class"
              class=""
              label="Classe"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.level_value"
              class=""
              label="Nível"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.atr_number"
              class=""
              label="ATR"
              outlined
              dense
              disabled
            />
          </div>

          <v-textarea
            v-model="data.human_resource_observation"
            label="Observações"
            outlined
            disabled
          />

          <div
            v-if="data.manager_approved_at"
            class="validations"
          >
            <span>Validações</span>

            <div
              v-if="data.manager_approved_at"
              class="infos"
            >
              <div class="image">
                <v-avatar
                  size="45px"
                  color="primary"
                  class="v-avatar-light-bg primary--text"
                >
                  <v-img src="../../../../../assets/avatar-rd.svg"></v-img>
                </v-avatar>
              </div>

              <div class="infos-aproved">
                <div>RENATA SVAIZER DO AMARAL BRITO</div>

                <div class="infos-text">
                  <small>{{ dateFormat(data.manager_approved_at) }}</small>

                  <div
                    class="tag"
                    :class="data.manager_approved_at ? 'aprovada' : 'reprovada'"
                  >
                    {{ data.manager_approved_at ? 'Aprovada' : 'Reprovada' }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="infos"
            >
              <div class="image">
                <v-avatar
                  size="45px"
                  color="primary"
                  class="v-avatar-light-bg primary--text"
                >
                  <v-img src="../../../../../assets/avatar-director.svg"></v-img>
                </v-avatar>
              </div>

              <div class="infos-aproved">
                <div>FERNANDO BATISTA DO AMARAL BRITO</div>

                <div class="infos-text">
                  <small>{{ dateFormat(data.director_approved_at) }}</small>

                  <div
                    class="tag"
                    :class="data.director_approved_at ? 'aprovada' : 'reprovada'"
                  >
                    {{ data.director_approved_at ? 'Aprovada' : 'Reprovada' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <Separator
            label="Informações do processo"
            class="mt-8 mb-12"
          />

          <div
            v-if="loadingHistory"
            class="progress-container-loading"
          >
            <v-progress-circular
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </div>

          <div
            v-else
            class="progress-container"
          >
            <span class="statusProcess">Status do Processo</span>

            <div
              v-for="(item, index) in dataTimeLine"
              :key="item.id"
              class="container-item"
            >
              <div class="container-icon">
                <img
                  v-if="item.status_timeline !== 'CANCELADO'"
                  src="../../../../../assets/rightIcon.svg"
                  width="20"
                >
                <img
                  v-else-if="item.status_timeline === 'CANCELADO'"
                  src="../../../../../assets/cancelIcon.svg"
                  width="20"
                >
                <img
                  v-else
                  src="../../../../../assets/waitingIcon.svg"
                  width="20"
                >

                <small :class="getStatusClass(index, item.status_timeline)">{{ item.status_timeline }}</small>

                <!-- <small :class="getStatusClass(index, item.new_status)">{{ hasConcluded ? 'CONCLUÍDO' : ( item.new_status === 'CANCELADO' ? 'CANCELADO' : 'CONCLUÍDO') }}</small> -->
              </div>
              <!-- <div class="item-time-line">
                <p>REQUISIÇÃO REALIZADA</p>
                <p>{{ dateFormat(data.created_at) }}</p>
              </div> -->
              <div :class="['item-time-line', {'no-border': index === dataTimeLine.length,} ],">
                <div
                  class=" d-flex"
                  style="gap: 43px;"
                >
                  <p>{{ item.new_status }}</p>

                  <div
                    class="d-flex flex-column"
                    style="gap: 10px;"
                  >
                    <v-tooltip
                      v-if="item.status_timeline === 'CANCELADO'"
                      top
                      color="gray"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="gray"
                          icon
                          v-bind="attrs"
                          @click="isExpand = !isExpand"
                          v-on="on"
                        >
                          <v-icon color="gray">
                            {{ icons.mdiChatProcessingOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Motivo</span>
                    </v-tooltip>
                  </div>
                </div>
                <p>{{ dateFormat(item.updated_at) }}</p>
                <v-expand-transition>
                  <div
                    v-if="item.status_timeline === 'CANCELADO' && isExpand"
                    class="d-flex flex-column"
                  >
                    <span style="font-size: 13px;">Motivo do cancelamento:</span>
                    <span style="font-size: 14px; margin-top: 10px">{{ data.cancellation_description }}</span>
                  </div>
                </v-expand-transition>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog
      v-model="showModalDP"
      width="600px"
    >
      <ModalSendDP
        :data="data"
        @close="showModalDP = false"
      ></ModalSendDP>
    </v-dialog>

    <v-dialog
      v-model="showModalRH"
      width="600px"
    >
      <ModalSendRH
        :data="data"
        @close="showModalRH = false"
      ></ModalSendRH>
    </v-dialog>
  </div>
</template>
<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import {
  mdiAccountOutline,
  mdiCalendarBlankOutline,
  mdiChatProcessingOutline,
  mdiCheckCircleOutline,
  mdiClose,
  mdiThumbDownOutline,
  mdiThumbUpOutline,
  mdiTimelineCheckOutline,
} from '@mdi/js'
import ModalSendDP from './ModalSendDP.vue'
import ModalSendRH from './ModalSendRH.vue'
import ApplicantInfo from './components/ApplicantInfo.vue'
import FieldsetApprovalTL from './components/FieldsetApprovalTL.vue'

export default {
  components: {
    ModalSendDP,
    ModalSendRH,
    Separator,
    ApplicantInfo,
    FieldsetApprovalTL,
  },
  mixins: [formatters],
  props: {
    data: {
      type: [String, Object],
      required: true,
    },
    profile: { type: String, default: '' },
  },

  data() {
    return {
      dataTimeLine: [],
      hasConcluded: false,
      dataHistory: [],
      loadingFinish: false,
      showModalRH: false,
      isExpand: false,
      currentDate: '',
      imageProfile: '',
      showModalDP: false,
      toggleSwitch: false,
      loadingCancel: false,
      loadingSendRD: false,
      tab: '',
      sectorValueid: '',
      radioValue: '',
      tabs: [
        { title: 'Requisição', icon: mdiAccountOutline },
        { title: 'Resultados', icon: mdiCheckCircleOutline },
        { title: 'Timeline', icon: mdiTimelineCheckOutline },
      ],
      disabledInputEmployee: false,
      loadingSubmitRequestSendDirector: false,
      listEmployees: [],
      listFunctions: [],
      listSectors: [],
      listGenders: ['MASCULINO', 'FEMININO', 'NÃO BINÁRIO'],
      listEducation: ['FUNDAMENTAL', 'MÉDIO', 'SUPERIOR', 'SUPERIOR INCOMPLETO'],
      typeContractValue: '',
      reasonRequestValue: '',
      replacedEmployeeValue: '',
      applicantName: '',
      applicantSector: '',
      applicantStore: '',
      reasonDescriptionValue: '',
      functionValue: '',
      sectorValue: '',
      ageValue: '',
      educationLevelValue: '',
      genderValue: '',
      requirementsVacancyValue: '',
      mainActivitiesValue: '',
      icons: {
        mdiClose,
        mdiAccountOutline,
        mdiThumbDownOutline,
        mdiThumbUpOutline,
        mdiCalendarBlankOutline,
        mdiCheckCircleOutline,
        mdiChatProcessingOutline,
      },
      listTypeContract: [
        'CONTRATO EFETIVO',
        'CONTRATO TEMPORÁRIO',
        'CONTRATO POR TEMPO DETERMINADO',
        'ESTAGIÁRIO',
        'APRENDIZ',
      ],
      listReasonRequest: [
        'AUMENTO DE QUADRO',
        'PROMOÇÃO',
        'SUBSTITUIÇÃO',
      ],
      status: 'EM ANDAMENTO', // Defina o status conforme necessário
      validStatuses: ['CRIADO', 'EM ANDAMENTO', 'AGUARDANDO DP', 'AGUARDANDO ENCERRAMENTO', 'CONCLUÍDO', 'STAND BY', 'CANCELADO'],
      loadingHistory: false,
      penultimateItem: '',
    }
  },
  computed: {
    substituteUserName() {
      return this.data.substitute_user ? this.data.substitute_user.name : ''
    },

    isStatusVisible() {
      return this.validStatuses.includes(this.data.status)
    },

    lastNonCompletedIndex() {
      if (this.hasConcluded) {
        return -1
      }

      // eslint-disable-next-line no-plusplus
      for (let i = this.dataHistory.length - 1; i >= 0; i--) {
        if (this.dataHistory[i].new_status !== 'CONCLUÍDO') {
          return i
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hasConcluded = true // Define como true se encontrar um concluído
      }

      return -1 // Retorna -1 se todos estiverem concluídos
    },
  },

  async created() {
    this.applicantName = this.data.creator.name
    this.applicantStore = this.data.creator.company.city
    this.applicantSector = this.data.creator.occupation_sector.sector.name
    this.sectorValueid = this.data.sector_id
    this.radioValue = this.data.is_for_acessories ? 'SIM' : 'NÃO'
    this.handleGetCurrentDate()

    this.handleGetStatusHistory()
  },

  methods: {
    handleCloseModalCanceled() {
      this.closeModal()
    },
    removeStatusCancel(list) {
      const lengthList = list.length

      // let dataList = list

      // eslint-disable-next-line no-param-reassign
      list = list.map(item => ({
        ...item,
        status_timeline: 'CONCLUÍDO',
      }))

      if (list[lengthList - 1].new_status === 'CANCELADO') {
        const newList = list.slice(0, lengthList - 1)

        newList[newList.length - 1].status_timeline = 'CANCELADO'

        return newList
      }

      return list
    },

    transformListTimeLine(list) {
      const listWithoutCancel = this.removeStatusCancel(list)
      listWithoutCancel.map(item => (
        {
          id: item.id,
          previousStatus: item.previous_status,
          newStatus: item.new_status,
          createdAt: item.created_at,
        }
      ))
      this.dataTimeLine = listWithoutCancel
    },

    getStatusClassValidations(status) {
      if (status === 'CANCELADO') {
        return 'tag-success'
      }

      return 'tag'
    },

    getStatusClass(index, status) {
      if (status === 'CANCELADO') {
        return 'status-cancelado'
      }

      // eslint-disable-next-line no-nested-ternary
      return this.hasConcluded ? 'status-concluido' : (index === this.lastNonCompletedIndex ? 'status-atual' : 'status-concluido')
    },
    isLast(item) {
      return this.dataHistory.indexOf(item) === this.dataHistory.length - 1
    },
    async handleGetStatusHistory() {
      if (!this.data?.id) {
        return
      }

      this.loadingHistory = true

      try {
        const infos = await axiosIns.get(`api/v1/personnel_request/employee_request_status_history/edit/${this.data.id}`)

        this.dataHistory = infos.data.data

        const penultimate = this.dataHistory[this.dataHistory.length - 2]?.previous_status

        this.penultimateItem = penultimate
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao requisitar os dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingHistory = false

        this.transformListTimeLine(this.dataHistory)
      }
    },

    async handleFinishRequest() {
      this.loadingFinish = true

      const body = {
        status: 'CONCLUÍDO',
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Requisição conluída',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingFinish = false
        this.updatedTable()
        this.closeModal()
      }
    },

    handleGetCurrentDate() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')

      const dataFormatada = `${year}-${month}-${day}`

      this.currentDate = dataFormatada
    },

    handleOpenModalSendDP() {
      this.showModalDP = true
    },

    async handleSendDirector() {
      this.loadingSubmitRequestSendDirector = true

      const body = {
        status: this.data.status === 'AGUARDANDO DIRETORIA' ? 'EM ANDAMENTO' : 'AGUARDANDO DIRETORIA',
      }

      if (this.data.status === 'AGUARDANDO DIRETORIA') body.director_approved_at = this.currentDate
      if (this.data.status === 'AGUARDANDO RD') body.manager_approved_at = this.currentDate

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Enviado para diretoria com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSubmitRequestSendDirector = false
        this.updatedTable()
        this.closeModal()
      }
    },
    async handleCancelRequest() {
      this.loadingCancel = true

      const body = {
        status: 'AGUARDANDO ENCERRAMENTO',
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Requisição recusada com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingCancel = false
        this.updatedTable()
        this.closeModal()
      }
    },
  },
}
</script>
<style scoped>
.isExpanded{
  transition: ease;
  transition-duration: 0.4s;
}
.isNotExpanded{
  transition: ease;
  transition-duration: 0.2s;
}
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.container-center{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.validations{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
}

.validations span{
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 8px;
}

.infos{
  display: flex;
  margin-top: 10px;
}

.image{

  display: flex;
  align-items: center;
  justify-content: center;
}

.tag{
  width: 85px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.aprovada{
  background: #56CA001A;
  color: #56CA00;
  margin-left: 10px;
}
.reprovada{
  background: #FF4C511A;
  color: #FF4C51;
}

.tag-success{
  background: #353C47;
  color: #00B212;
  width: 85px;
  padding: 3px;
  font-size: 10px;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px
}

.infos-text{
  display: flex;
  align-items: center;
}

.progress-container{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;
}

.progress-container-loading{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

}

.statusProcess{
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 12px;
  font-size: 12px;
}

.item-time-line{
  border-left: 1px dashed #8C8C8C;
  padding: 10px;
  margin-left: 9px;

  p{
    font-size: 12px;
  }
}

.container-item{
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  & + &{
    margin-top: 0px;

  }
}

.item-time-line-waiting{
  padding: 10px;
  margin-left: 9px;

  p{
    font-size: 12px;
  }
}

.container-icon-waiting{
  display: flex;

  small{
    font-size: 12px;
    margin-left: 8px;
    color: #FFB270;
  }
}

.container-icon{
  display: flex;

  small{
    font-size: 12px;
    margin-left: 8px;
  }

  .status-cancelado {
    color: #FF4854;
  }

  .status-atual {
    color: #FFB270;
  }

  .status-concluido {
    color: #39CE2F;
  }
}

.stand-by{
  display: flex;
  align-items: center;

  p{
    margin-top: 12px;
  }
}

.footer{
  display: flex;
  justify-content: center;
}

.inputs-approved{
  margin-top: 20px;
}

.wrapper-inputs{
  display: flex;
  gap: 10px;
}

.actions-finish{
  display: flex;
  align-items: center;
  justify-content: center;
}

.infos-aproved{
  margin-left: 5px;
}
.no-border {
  border-left: none;
}
.tabs-just{
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
