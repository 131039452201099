<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Requisição de Pessoal</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModal"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4">
        <span>👋 Olá, para requisitar, basta preencher os campos abaixo!</span>
      </div>
      <Separator
        label="Justificativa"
        class="mt-3"
      />

      <v-form ref="form">
        <div class="line">
          <v-autocomplete
            v-model="typeContractValue"
            class=""
            :items="listTypeContract"
            :rules="[rules.required]"
            label="Tipo de Contrato"
            outlined
            dense
          />

          <v-autocomplete
            v-model="reasonRequestValue"
            class=""
            :items="listReasonRequest"
            :rules="[rules.required]"
            label="Motivo da Requisição"
            outlined
            dense
          />
        </div>

        <v-autocomplete
          v-if="reasonRequestValue === 'SUBSTITUIÇÃO'"
          v-model="replacedEmployeeValue"
          class=""
          :items="listEmployees"
          label="Funcionário Substituído"
          item-text="name"
          item-value="id"
          outlined
          dense
          :disabled="reasonRequestValue !== 'SUBSTITUIÇÃO'"
          :rules="[reasonRequestValue === 'SUBSTITUIÇÃO' ? rules.required : null]"
        />

        <v-textarea
          v-model="reasonDescriptionValue"
          :rules="[rules.required]"
          label="Descrição do motivo"
          outlined
        />
        <Separator
          label="Dados da vaga"
          class=""
        />

        <v-autocomplete
          v-model="sectorValue"
          class="mt-6"
          :items="listSectors"
          :rules="[rules.required]"
          label="Setor"
          item-text="name"
          item-value="id"
          outlined
          dense
          @change="getOcuppations(sectorValue)"
        />
        <v-autocomplete
          v-model="functionValue"
          class=""
          :items="listFunctions"
          :rules="[rules.required]"
          label="Função"
          item-text="name"
          :disabled="!isFunctionAvailable"
          item-value="id"
          outlined
          dense
        />

        <div
          v-if="sectorValue === 'ff5f9668-d5b7-42f5-ae2b-5fe34854a2a0' || sectorValue === 'd911e3ed-a989-479e-a8e9-36debec9df70' || sectorValue === '6ce1e394-daf0-4294-827c-4d05eb4b4f91' || sectorValue === '38b9b784-053f-4587-b8c6-346baad569dd' || sectorValue === 'efabfa1d-da9a-482d-baa5-7af8500f5101'"
          style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 20px; height: 30px;"
        >
          <div>
            <p>A função terá a responsabilidade de vender acessórios?</p>

            <v-radio-group
              v-model="radioValue"
              row
              :rules="[rules.required]"
            >
              <v-radio
                label="Sim"
                value="SIM"
                color="#9C43FA"
              />

              <v-radio
                label="Não"
                value="NÃO"
                color="#9C43FA"
              />
            </v-radio-group>
          </div>
        </div>

        <div class="line mt-0">
          <v-text-field
            v-model="ageValue"
            type="number"
            class=""
            label="Idade"
            outlined
            dense
          />

          <v-autocomplete
            v-model="genderValue"
            class=""
            :items="listGenders"
            label="Sexo"
            outlined
            dense
          />
          <v-autocomplete
            v-model="educationLevelValue"
            class=""
            :items="listEducation"
            :rules="[rules.required]"
            label="Nível de escolaridade"
            outlined
            dense
          />
        </div>

        <v-textarea
          v-model="requirementsVacancyValue"
          label="Requisitos da Vaga"
          :rules="[rules.required]"
          outlined
        />

        <v-textarea
          v-model="mainActivitiesValue"
          label="Principais Atividades"
          :rules="[rules.required]"
          outlined
        />
      </v-form>

      <div class="container-center">
        <v-btn
          color="info"
          class="ml-4"
          :disabled="loadingSubmitRequest"
          @click="handleSubmit"
        >
          <div
            v-if="!loadingSubmitRequest"
            class="d-flex align-center"
            style="gap: 5px"
          >
            <v-img src="../../../../../assets/images/svg/mdiAccountArrowUpOutline.svg"></v-img>
            <span>REQUISITAR</span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import { mdiAccountArrowRightOutline, mdiClose } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'

export default {
  components: {
    Separator,
  },
  mixins: [formatters],

  data() {
    return {
      disabledInputEmployee: false,
      loadingSubmitRequest: false,
      radioValue: '',

      listEmployees: [],
      listFunctions: [],
      listSectors: [],

      listGenders: ['FEMININO', 'MASCULINO', 'NÃO INFORMAR'],
      listEducation: ['FUNDAMENTAL', 'MÉDIO', 'SUPERIOR', 'SUPERIOR INCOMPLETO'],
      typeContractValue: '',
      reasonRequestValue: '',
      replacedEmployeeValue: '',
      reasonDescriptionValue: '',
      functionValue: '',
      isAccessoriesSeller: false,
      sectorValue: '',
      ageValue: '',
      educationLevelValue: '',
      genderValue: '',
      requirementsVacancyValue: '',
      mainActivitiesValue: '',

      icons: {
        mdiClose, mdiAccountArrowRightOutline,
      },

      listTypeContract: [
        'APRENDIZ',
        'CONTRATO EFETIVO',
        'CONTRATO TEMPORÁRIO',
        'CONTRATO POR TEMPO DETERMINADO',
        'ESTAGIÁRIO',
      ],

      listReasonRequest: [
        'AUMENTO DE QUADRO',
        'PROMOÇÃO',
        'SUBSTITUIÇÃO',
      ],
    }
  },
  computed: {
    isFunctionAvailable() {
      return this.listFunctions.length > 0
    },
  },

  created() {
    this.listEmployees = localStorageSlim.get('listEmployees', { decrypt: true })

    this.getData()
  },
  methods: {
    async getOcuppations(id) {
      try {
        const response = await axiosIns.get(`/api/v1/records/occupation/find_by_sector_id/${id}`)

        this.listFunctions = response.data.data
      } catch (error) {
        console.error(error)
      }
    },
    async getData() {
      try {
        const response = await axiosIns.get('api/v1/records/sector/index')

        this.listSectors = response.data.data
      } catch (error) {
        console.error(error)
      }
    },

    handleCloseModal() {
      // this.typeContractValue = ''
      // this.reasonRequestValue = ''
      // this.functionValue = ''
      // this.sectorValue = ''
      // this.ageValue = ''
      // this.genderValue = ''
      // this.educationLevelValue = ''
      // this.requirementsVacancyValue = ''
      // this.mainActivitiesValue = ''
      // this.replacedEmployeeValue = ''
      // this.reasonDescriptionValue = ''

      // this.$refs.form.reset()

      this.closeModal()
    },

    async handleSubmit() {
      const formIsValid = this.$refs.form.validate()

      if (!formIsValid) return

      const body = {
        contract_types: this.typeContractValue.toUpperCase(),
        request_reason: this.reasonRequestValue.toUpperCase(),
        occupation_id: this.functionValue,
        sector_id: this.sectorValue,
        age: Number(this.ageValue),
        sex: this.genderValue,
        education_level: this.educationLevelValue,
        vacancy_requirements: this.requirementsVacancyValue,
        main_activities: this.mainActivitiesValue,
        substitute_user_id: this.replacedEmployeeValue,
        request_reason_description: this.reasonDescriptionValue,
        is_for_acessories: this.radioValue === 'SIM',
      }

      try {
        this.loadingSubmitRequest = true
        await axiosIns.post('api/v1/personnel_request/employee_request/store', body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Requisição feita com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        console.error({ body, error })

        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSubmitRequest = false
        this.updatedTable()
        this.closeModal()

        this.$refs.form.reset()
      }
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.container-center{
  display: flex;
  justify-content: center;
}
</style>
