<template>
  <v-card>
    <div
      class="mx-7 d-flex flex-column align-center justify-center pb-8"
    >
      <div class="head-modal">
        <div></div>

        <h3 style="font-weight: 500; font-size: 18px;">
          Reprovar Requisição de Pessoal
        </h3>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModal"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <h3
        class="mt-4 mb-6 text-center px-8"
        style="font-size: 14px; font-weight: 400;"
      >
        👋 Olá, líder! Antes de prosseguir, poderia nos explicar os motivos para a reprovação?
      </h3>
      <v-form
        ref="form"
        style="width: 100%;"
        class="d-flex flex-column align-center justify-center"
      >
        <v-textarea
          v-model="descriptionReason"
          style="width: 100%;"
          label="Descrição do motivo"
          :rules="[rules.required]"
          outlined
          auto-grow
        ></v-textarea>
        <v-btn
          color="error"
          class="d-flex align-center justify-center"
          @click="handleLeaderDisapprove"
        >
          <v-icon class="">
            {{ icons.mdiThumbDown }}
          </v-icon>
          <span class="pl-3">REPROVAR</span>
        </v-btn>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import formatters from '@/plugins/formattersMixin1'
import { mdiClose, mdiThumbDown } from '@mdi/js'

export default {
  name: 'ModalLeaderDisapproval',

  mixins: [formatters],

  props: {
    data: {
      type: [Object],
      required: true,
    },
    profile: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      icons: {
        mdiThumbDown, mdiClose,
      },
      descriptionReason: '',
    }
  },
  methods: {
    async handleLeaderDisapprove() {
      const formIsValid = this.$refs.form.validate()
      if (!formIsValid) return

      const body = {
        employee_request_id: this.data.employee_request_id,
        user_id: this.profile.id,
        vote: 'Reprovado',
        description: this.descriptionReason,
      }

      await this.postRegister(
        '/api/v1/personnel_request/employee_request_user/store',
        body,
        'Deseja reprovar a requisição?',
        'Essa ação reprovará a requisição e não poderá ser desfeita.',
      )
      this.$emit('updateVotes')
      this.$emit('updateTableIndex')
      this.handleCloseModal()
      this.$emit('closeMainModal')
    },

    handleCloseModal() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.head-modal {
  width: 100%;
  display: flex;
  padding-top: 30px;
  justify-content: space-between;
  align-items: center;
}
</style>
