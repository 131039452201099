<template>
  <div>
    <Separator
      label="Dados do solicitante"
      class="mt-8"
    />
    <div
      class="py-5 d-flex flex-column"
      style="gap: 18px;"
    >
      <div
        class="d-flex"
        style="gap: 27px;"
      >
        <div
          class="d-flex"
          style="gap: 11px;"
        >
          <v-icon color="info">
            {{ icons.mdiAccountOutline }}
          </v-icon>
          <h3 style="font-weight: 500; font-size: 13px; padding-top: 4px;">
            <strong style="font-weight: 600; font-size: 14px">Solicitante:</strong> {{ applicantName }}
          </h3>
        </div>
        <div
          class="d-flex"
          style="gap: 11px;"
        >
          <v-icon color="info">
            {{ icons.mdiOfficeBuildingMarkerOutline }}
          </v-icon>
          <h3 style="font-weight: 500; font-size: 13px; padding-top: 4px;">
            <strong style="font-weight: 600; font-size: 14px">Loja:</strong> {{ applicantStore }}
          </h3>
        </div>
      </div>
      <div
        class="d-flex"
        style="gap: 11px;"
      >
        <v-icon
          color="info"
        >
          {{ icons.mdiDoorClosed }}
        </v-icon>
        <h3 style="font-weight: 500; font-size: 13px; padding-top: 4px;">
          <strong style="font-weight: 600; font-size: 14px">Setor:</strong> {{ applicantSector }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import Separator from '@/components/Separator.vue'
import {
  mdiAccountOutline,
  mdiDoorClosed, mdiOfficeBuildingMarkerOutline,
} from '@mdi/js'

export default {
  name: 'ApplicantInfo',
  components: {
    Separator,
  },
  props: {
    applicantName: { type: String, default: '' },
    applicantStore: { type: String, default: '' },
    applicantSector: { type: String, default: '' },
  },
  data() {
    return {
      icons: {
        mdiDoorClosed, mdiOfficeBuildingMarkerOutline, mdiAccountOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
