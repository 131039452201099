<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Requisição de Pessoaasl</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalRd"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          class="tabs-just"
        >
          <div>
            <v-tooltip
              v-if="theresNotification(tab)"
              color="orange"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="orange"
                  size="20"
                  class="pb-4"
                  style="position: absolute; z-index: 1; margin-left: -12px;"
                  v-on="on"
                >
                  {{ icons.mdiExclamationThick }}
                </v-icon>
              </template>
              <span>Importante</span>
            </v-tooltip>

            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
          </div>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <ApplicantInfo
            :applicant-name="applicantName"
            :applicant-sector="applicantSector"
            :applicant-store="applicantStore"
          />
          <Separator
            label="Justificativa"
            class="mt-6"
          />

          <div class="line">
            <v-text-field
              v-model="data.contract_types"
              class=""
              label="Tipo de Contrato"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="data.request_reason"
              class=""
              label="Motivo da Requisição"
              outlined
              dense
              disabled
            />
          </div>

          <v-text-field
            v-if="data.request_reason === 'SUBSTITUIÇÃO'"
            v-model="data.substitute_user.name"
            class=""
            label="Funcionário Substituído"
            outlined
            dense
            disabled
          />

          <v-textarea
            v-model="data.request_reason_description"
            label="Descrição do motivo"
            outlined
            disabled
          />
          <Separator
            label="Dados da vaga"
            class="mt-0 mb-8"
          />

          <v-text-field
            v-model="data.occupation.name"
            class="mt-5"
            label="Função"
            outlined
            dense
            disabled
          />

          <v-text-field
            v-model="data.sector.name"
            class=""
            label="Setor"
            outlined
            dense
            disabled
          />

          <div
            v-if="sectorValueid === 'ff5f9668-d5b7-42f5-ae2b-5fe34854a2a0' || sectorValueid === 'd911e3ed-a989-479e-a8e9-36debec9df70' || sectorValueid === '6ce1e394-daf0-4294-827c-4d05eb4b4f91' || sectorValueid === '38b9b784-053f-4587-b8c6-346baad569dd' || sectorValueid === 'efabfa1d-da9a-482d-baa5-7af8500f5101'"
            style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 25px; height: 30px;"
          >
            <div>
              <p>A função terá a responsabilidade de vender acessórios?</p>

              <v-radio-group
                v-model="radioValue"
                row
                disabled
              >
                <v-radio
                  label="Sim"
                  value="SIM"
                  color="#9C43FA"
                />

                <v-radio
                  label="Não"
                  value="NÃO"
                  color="#9C43FA"
                />
              </v-radio-group>
            </div>
          </div>

          <div class="line mt-0">
            <v-text-field
              v-model="data.age"
              class=""
              label="Idade"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="data.sex"
              class=""
              label="Sexo"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.education_level"
              class=""
              label="Nível de escolaridade"
              outlined
              dense
              disabled
            />
          </div>

          <v-textarea
            v-model="data.vacancy_requirements"
            label="Requisitos da Vaga"
            outlined
            disabled
          />

          <v-textarea
            v-model="data.main_activities"
            label="Principais Atividades"
            outlined
            disabled
          />

          <FieldsetApprovalTL
            :data="data"
            :profile="profile"
          />
        </v-tab-item>
        <v-tab-item>
          <Separator
            label="Dados da vaga"
            class="mt-6"
          />
          <v-text-field
            v-model="data.occupation.name"
            class="mt-5"
            label="Função"
            outlined
            dense
            disabled
          />

          <v-text-field
            v-model="data.sector.name"
            class=""
            label="Setor"
            outlined
            dense
            disabled
          />

          <div class="line mt-0">
            <v-text-field
              v-model="data.salary_value"
              class=""
              label="Salário"
              outlined
              dense
              prefix="R$"
              disabled
            />

            <v-text-field
              v-model="data.class"
              class=""
              label="Classe"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.level_value"
              class=""
              label="Nível"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.atr_number"
              class=""
              label="ATR"
              outlined
              dense
              disabled
            />
          </div>

          <v-textarea
            v-model="data.human_resource_observation"
            label="Observações"
            outlined
            disabled
          />

          <div
            v-if="data.manager_approved_at"
            class="validations"
          >
            <span>Validações</span>

            <div
              v-if="data.manager_approved_at"
              class="infos"
            >
              <div class="image">
                <v-avatar
                  size="45px"
                  color="primary"
                  class="v-avatar-light-bg primary--text"
                >
                  <v-img src="../../../../../assets/avatar-rd.svg"></v-img>
                </v-avatar>
              </div>

              <div class="infos-aproved">
                <p>RENATA SVAIZER DO AMARAL BRITO</p>

                <div class="infos-text">
                  <small>{{ dateFormat(data.manager_approved_at) }}</small>

                  <div class="tag">
                    {{ data.status === 'AGUARDANDO ENCERRAMENTO' && !data.manager_approved_at ? 'Reprovada' : 'Aprovada' }}
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="data.director_approved_at"
              class="infos"
            >
              <div class="image">
                <v-avatar
                  size="45px"
                  color="primary"
                  class="v-avatar-light-bg primary--text"
                >
                  <v-img src="../../../../../assets/avatar-director.svg"></v-img>
                </v-avatar>
              </div>

              <div class="infos-aproved">
                <p>FERNANDO BATISTA DO AMARAL BRITO</p>

                <div class="infos-text">
                  <small>{{ dateFormat(data.director_approved_at) }}</small>

                  <div class="tag">
                    {{ data.status === 'AGUARDANDO ENCERRAMENTO' && !data.director_approved_at ? 'Reprovada' : 'Aprovada' }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="data.status === 'AGUARDANDO RD' || data.status === 'AGUARDANDO DIRETORIA'"
            class="container-center"
          >
            <v-btn
              v-if="profile === 'Diretor'"
              color="error"
              class="ml-4"
              @click="handleOpenModalDirectorCancel"
            >
              <div v-if="!loadingCancel">
                <v-icon
                  medium
                  class="me-2"
                >
                  {{ icons.mdiThumbDown }}
                </v-icon>
                <span>REPROVAR</span>
              </div>

              <v-progress-circular
                v-else
                color="white"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>

            <v-btn
              v-if="profile === 'Diretor'"
              color="success"
              class="ml-4"
              @click="handleSendDirector"
            >
              <div v-if="!loadingSubmitRequestSendDirector">
                <v-icon
                  medium
                  class="me-2"
                >
                  {{ icons.mdiThumbUp }}
                </v-icon>
                <span>APROVAR</span>
              </div>

              <v-progress-circular
                v-else
                color="white"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>
          </div>
        </v-tab-item>
        <v-tab-item v-if="data.status === 'AGUARDANDO ENCERRAMENTO' || data.status === 'CONCLUÍDO' || data.status === 'AGUARDANDO DP' || data.status === 'EM ANDAMENTO'">
          <div v-if="data.status === 'EM ANDAMENTO' || data.status === 'AGUARDANDO DP'">
            <Separator
              label="Informações do aprovado"
              class="my-6"
            />

            <ResultsWrapper
              :key="ResultsWrapperKey"
              :profile="profile"
              :data="data"
              :theres-notifications="returnedToRH"
              @openSendDpModal="handleOpenModalSendDP"
              @AtualizaAi="handleUpdatedTable"
              @showModalRH="showModalRH = true"
              @arrayStatus="theresClassified = $event"
            />
            <!-- <div
              v-if="profile === 'Departamento Pessoal'"
              class="center-div"
            >
              <v-btn
                color="success"
                @click="showModalRH = true"
              >
                <div>
                  <span>Contratar</span>
                </div>
              </v-btn>
            </div> -->
            <div
              v-if="data.status === 'EM ANDAMENTO' && profile === 'Recursos Humanos'"
              class="stand-by"
            >
              <v-switch
                v-model="toggleSwitch"
                inset
                color="purple"
              />
              <p>Stand by</p>

              <v-progress-circular
                v-if="loadingStadBy"
                color="gray"
                :size="15"
                indeterminate
                class="ml-2"
                width="3"
              >
              </v-progress-circular>
            </div>
            <div
              v-if="data.status === 'EM ANDAMENTO' && profile === 'Recursos Humanos'"
              style="width: 100%;"
              class=" d-flex justify-space-between mt-5"
            >
              <v-btn
                color="error"
                :disabled="isSendingToLeader"
                @click="showModalCancel = true"
              >
                <div
                  class="d-flex align-center"
                  style="gap: 5px"
                >
                  <span>CANCELAR</span>
                  <v-icon>{{ icons.mdiCancel }}</v-icon>
                </div>
              </v-btn>
              <v-btn
                color="success"
                :disabled="isSendingToLeader || theresClassified"
                @click="handleSendToLeader"
              >
                <div
                  class="d-flex align-center"
                  style="gap: 5px"
                >
                  <span>ENVIAR PARA O LÍDER</span>
                  <v-icon>{{ icons.mdiShare }}</v-icon>
                </div>
              </v-btn>
            </div>
          </div>
          <div v-if="data.status === 'AGUARDANDO ENCERRAMENTO' || data.status === 'CONCLUÍDO'">
            <Separator
              label="Informações do aprovado"
              class="my-8"
            />

            <div v-if="!data.manager_1_id">
              <div>
                <v-text-field
                  v-model="data.hired_person_name"
                  class=""
                  label="Nome"
                  outlined
                  dense
                  disabled
                />
                <div
                  class="d-flex"
                  style="gap: 21px;"
                >
                  <v-text-field
                    v-model="data.hired_person_cpf"
                    class=""
                    label="CPF"
                    outlined
                    dense
                    disabled
                  />
                  <v-text-field
                    v-model="expectedStart"
                    class=""
                    style="width: 100px;"
                    label="Previsão de admissão"
                    :append-icon="icons.mdiCalendar"
                    outlined
                    dense
                    disabled
                  />
                </div>
                <fieldset
                  class="py-3 px-3"
                  style="border-radius: 5px"
                >
                  <legend class="px-2">
                    Documentos
                  </legend>
                  <v-chip
                    v-for="file in files"
                    v-show="computedViewFileContainer"
                    :key="file.id"
                    size="small"
                    color="warning"
                    class="chips-content"
                    outlined
                    dense
                    @click="downloadFile(file.id, file.name)"
                  >
                    <span v-if="file.id === isLoadingFile ? false : true">{{ file.name }}</span>
                    <v-progress-circular
                      v-else
                      :key="file.id"
                      :size="25"
                      color="white"
                      indeterminate
                    />
                  </v-chip>
                </fieldset>
              </div>
              <Separator
                label="Outras informações"
                class="my-8"
              />

              <div>
                <v-textarea
                  v-model="data.rh_observations"
                  class="mt-6"
                  disabled
                  label="Parecer do RH"
                  outlined
                />
                <v-textarea
                  v-model="data.leader_observations"
                  class="mt-6"
                  disabled
                  label="Parecer do requisitante"
                  outlined
                />
              </div>
              <Separator
                label="Informações DP"
                class="my-8"
              />
              <div
                class="d-flex"
                style="gap: 45px; justify-items: start; align-items: start;"
              >
                <v-text-field
                  v-model="hiredDate"
                  class=""
                  style="width: 100px;"
                  label="Previsão de admissão"
                  :append-icon="icons.mdiCalendar"
                  outlined
                  dense
                  disabled
                />
                <v-switch
                  v-model="data.was_person_reproved"
                  class="mt-0"
                  color="purple"
                  disabled
                  inset
                  label="Reprovado no exame admissional"
                ></v-switch>
              </div>
            </div>

            <ResultsWrapper
              :key="ResultsWrapperKey"
              :profile="profile"
              :data="data"
              :theres-notifications="theresNotifications"
              @updatedTable="handleUpdatedTable"
              @openSendDpModal="handleOpenModalSendDP"
              @arrayStatus="theresClassified = $event"
              @AtualizaAi="handleUpdatedTable"
            />

            <div class="inputs-approved">
              <div
                v-if="data.status !== 'CONCLUÍDO' && profile === 'Recursos Humanos'"
                class="actions-finish"
              >
                <v-btn
                  color="error"
                  class="ml-4"
                  @click="showModalCancel = true"
                >
                  <div>
                    <span>cancelar</span>
                  </div>
                </v-btn>
                <v-btn
                  v-if="!data.was_person_reproved"
                  color="success"
                  class="ml-4"
                  @click="handleFinishRequest"
                >
                  <div v-if="!loadingFinish">
                    <span>concluir</span>
                  </div>
                  <v-progress-circular
                    v-else
                    color="white"
                    indeterminate
                  >
                  </v-progress-circular>
                </v-btn>
              </div>
            </div>
            <div v-if="data.status === 'AGUARDANDO DP'">
              <Separator
                label="Informações do aprovado"
                class="my-8"
              />
              <div class="inputs-approved">
                <v-text-field
                  v-model="data.hired_person_name"
                  class=""
                  label="Nome"
                  outlined
                  dense
                  disabled
                />
                <div class="wrapper-inputs">
                  <v-text-field
                    v-model="data.hired_person_cpf"
                    class=""
                    label="CPF"
                    outlined
                    dense
                    disabled
                  />
                  <v-text-field
                    v-model="data.expected_start_date"
                    class=""
                    label="Previsão de admissão"
                    outlined
                    dense
                    disabled
                  />
                </div>
              </div>
              <v-chip
                v-for="file in files"
                v-show="computedViewFileContainer"
                :key="file.id"
                size="small"
                color="warning"
                class="chips-content"
                outlined
                dense
                @click="downloadFile(file.id, file.name)"
              >
                <span v-if="file.id === isLoadingFile ? false : true">{{ file.name }}</span>
                <v-progress-circular
                  v-else
                  :key="file.id"
                  :size="25"
                  color="white"
                  indeterminate
                />
              </v-chip>
              <h4
                v-show="!computedViewFileContainer && !isLoadingGetFile"
                class="font-100"
              >
                - Nenhum anexo
              </h4>
              <Separator
                label="Outras informações"
                class="mt-6"
              />
              <v-textarea
                v-model="data.rh_observations"
                class="mt-6"
                label="Parecer do RH"
                outlined
                disabled
              />
              <v-textarea
                v-model="data.leader_observations"
                disabled
                label="Parecer do Requisitante"
                outlined
              />
            </div>
            <div
              v-if="data.status === 'EM ANDAMENTO' && profile === 'Recursos Humanos'"
              class="stand-by"
            >
              <v-switch
                v-model="toggleSwitch"
                inset
                color="purple"
              />
              <p>Stand by</p>
              <p>Stand by</p>

              <p>Stand by</p>

              <v-progress-circular
                v-if="loadingStadBy"
                color="gray"
                :size="15"
                indeterminate
                class="ml-2"
                width="3"
              >
              </v-progress-circular>
            </div>
            <div
              v-if="data.status === 'EM ANDAMENTO' && profile === 'Recursos Humanos'"
              class="footer"
            >
              <v-btn
                color="error"
                class=""
                @click="showModalCancel = true"
              >
                <div
                  v-if="!loadingCancel"
                  class="d-flex align-center"
                  style="gap: 5px"
                >
                  <span>Cancelar</span>
                  <v-icon>{{ icons.mdiCancel }}</v-icon>
                </div>
                <v-progress-circular
                  v-else
                  color="white"
                  indeterminate
                >
                </v-progress-circular>
              </v-btn>
              <v-btn
                color="success"
                class=""
                :disabled="isSendingToLeader"
                @click="handleSendToLeader"
              >
                <div
                  v-if="!isSendingToLeader"
                  class="d-flex align-center"
                  style="gap: 5px"
                >
                  <span>Enviar para o líder</span>
                  <v-icon>{{ icons.mdiShare }}</v-icon>
                </div>
                <v-progress-circular
                  v-else
                  color="white"
                  indeterminate
                >
                </v-progress-circular>
              </v-btn>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <Separator
            class="mt-10 mb-12"
            label="Histórico do processo"
          />
          <div
            v-if="loadingHistory"
            class="progress-container-loading"
          >
            <v-progress-circular
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </div>

          <div
            v-else
            class="progress-container"
          >
            <span>Status do Processo</span>

            <div class="container-icon">
              <img
                src="../../../../../assets/rightIcon.svg"
                width="20"
              >

              <small class="status-concluido">CONCLUÍDO</small>
            </div>

            <div class="item-time-line-first">
              <p>REQUISIÇÃO REALIZADA</p>
              <p>{{ dateFormat(data.created_at) }}</p>
            </div>

            <div
              v-for="(item, index) in dataHistory"
              :key="item.id"
              class="container-item"
            >
              <div class="container-icon">
                <img
                  v-if="index !== lastNonCompletedIndex || hasConcluded"
                  src="../../../../../assets/rightIcon.svg"
                  width="20"
                >
                <img
                  v-else
                  src="../../../../../assets/waitingIcon.svg"
                  width="17"
                >

                <small :class="getStatusClass(index)">{{ hasConcluded ? 'CONCLUÍDO' : (index === lastNonCompletedIndex ? 'STATUS ATUAL' : 'CONCLUÍDO') }}</small>
              </div>

              <div :class="['item-time-line', {'no-border': index === dataHistory.length - 1}]">
                <p>{{ item.new_status }} </p>
                <p>{{ dateFormat(item.updated_at) }}</p>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog
      v-model="showModalDP"
      width="600px"
    >
      <ModalSendLeader
        :data="data"
        @changeResultsWrapperKey="handleChangeResultsWrapperKey"
        @closeRD="handleCloseModalRd"
        @closeModalRdDp="showModalDP = false"
        @close="showModalDP = false"
        @updatedTable="handleUpdatedTable"
        @updateResults="handleGetApplicantData"
      ></ModalSendLeader>
    </v-dialog>
    <v-dialog
      v-model="isApprovedRegisterModalOpen"
      width="600px"
    >
      <ApprovedRegisterModal
        :data="data"
        @close="isApprovedRegisterModalOpen = false"
        @updatedTable="handleUpdatedTable"
      ></ApprovedRegisterModal>
    </v-dialog>

    <v-dialog
      v-model="showModalRH"
      width="600px"
    >
      <ModalSendRH
        :data="data"
        @close="showModalRH = false"
        @closeRD="handleCloseModalRd"
        @updatedTable="handleUpdatedTable"
      ></ModalSendRH>
    </v-dialog>

    <v-dialog
      v-model="showModalCancel"
      width="540px"
    >
      <ModalCancel
        :data="dataCancel"
        @close="showModalCancel = false"
        @updatedTable="handleUpdatedTable"
      ></ModalCancel>
    </v-dialog>
    <v-dialog
      v-model="showModalDirectorCancel"
      width="540px"
    >
      <ModalDirectorCancel
        :data="dataCancel"
        @close="showModalDirectorCancel = false"
        @updatedTable="handleUpdatedTable"
      ></ModalDirectorCancel>
    </v-dialog>
  </div>
</template>
<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiCalendar,
  mdiCalendarBlankOutline,
  mdiCancel,
  mdiCheckCircleOutline,
  mdiClose,
  mdiExclamationThick,
  mdiPlus,
  mdiShare,
  mdiThumbDown,
  mdiThumbUp,
  mdiTimelineCheckOutline,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import LocalStorageSlim from 'localstorage-slim'
import ModalCancel from './ModalCancel.vue'
import ModalDirectorCancel from './ModalDirectorCancel.vue'
import ModalSendLeader from './ModalSendLeader.vue'
import ModalSendRH from './ModalSendRH.vue'
import ApplicantInfo from './components/ApplicantInfo.vue'
import FieldsetApprovalTL from './components/FieldsetApprovalTL.vue'
import ResultsWrapper from './components/ResultsWrapper.vue'

export default {
  components: {
    ModalSendLeader,
    Separator,
    ModalSendRH,
    ModalDirectorCancel,
    ModalCancel,
    ApplicantInfo,
    FieldsetApprovalTL,
    ResultsWrapper,
  },
  mixins: [formatters],
  props: {
    data: {
      type: [String, Object],
      required: true,
    },
    closeModalrd: { type: Function, default: () => {} },
    profile: { type: String, default: '' },
    id: { type: String, default: '' },
  },

  data() {
    return {
      ResultsWrapperKey: null,
      files: [],
      dataHistory: [],
      dataCancel: {},
      tabs: [
        { title: 'Requisição', icon: mdiAccountOutline },
        { title: 'Validação', icon: mdiAlertCircleOutline },
        { title: 'TimeLine', icon: mdiTimelineCheckOutline },
      ],
      listEmployees: [],
      listFunctions: [],
      listSectors: [],
      listGenders: ['MASCULINO', 'FEMININO', 'NÃO BINÁRIO'],
      listEducation: ['FUNDAMENTAL', 'MÉDIO', 'SUPERIOR', 'SUPERIOR INCOMPLETO'],
      icons: {
        mdiPlus,
        mdiClose,
        mdiAccountOutline,
        mdiThumbDown,
        mdiThumbUp,
        mdiCalendarBlankOutline,
        mdiCheckCircleOutline,
        mdiTimelineCheckOutline,
        mdiShare,
        mdiCancel,
        mdiExclamationThick,
        mdiCalendar,
      },
      listTypeContract: [
        'CONTRATO EFETIVO',
        'CONTRATO TEMPORÁRIO',
        'CONTRATO POR TEMPO DETERMINADO',
        'ESTAGIÁRIO',
        'APRENDIZ',
      ],
      userProfile: {},
      listReasonRequest: [
        'AUMENTO DE QUADRO',
        'PROMOÇÃO',
        'SUBSTITUIÇÃO',
      ],
      status: 'EM ANDAMENTO',
      validStatuses: ['EM ANDAMENTO', 'AGUARDANDO DP', 'AGUARDANDO ENCERRAMENTO', 'CONCLUÍDO', 'STAND BY', 'CANCELADO'],
      isLoadingGetFile: false,
      isViewingFile: false,
      loadingStadBy: false,
      showModalDirectorCancel: false,
      showModalCancel: false,
      returnedToRH: false,
      hasConcluded: false,
      loadingFinish: false,
      showModalRH: false,
      loadingSendRD: false,
      showModalDP: false,
      toggleSwitch: false,
      loadingCancel: false,
      disabledInputEmployee: false,
      loadingHistory: false,
      loadingSubmitRequestSendDirector: false,
      isApprovedRegisterModalOpen: false,
      isSendingToLeader: false,
      theresClassified: true,

      currentDate: '',
      imageProfile: '',
      sectorValueid: '',
      radioValue: '',
      isLoadingFile: '',
      tab: '',
      typeContractValue: '',
      reasonRequestValue: '',
      replacedEmployeeValue: '',
      reasonDescriptionValue: '',
      functionValue: '',
      sectorValue: '',
      expectedStart: '',
      hiredDate: '',
      ageValue: '',
      educationLevelValue: '',
      genderValue: '',
      requirementsVacancyValue: '',
      mainActivitiesValue: '',
      applicantName: '',
      applicantSector: '',
      applicantStore: '',
    }
  },

  computed: {
    computedViewFileContainer() {
      const isFileLoading = !this.isLoadingGetFile
      const isFileViewing = this.isViewingFile

      return isFileLoading && isFileViewing
    },

    substituteUserName() {
      return this.data.substitute_user ? this.data.substitute_user.name : ''
    },

    isStatusVisible() {
      return this.validStatuses.includes(this.data.status)
    },

    lastNonCompletedIndex() {
      if (this.hasConcluded) {
        return -1
      }

      // eslint-disable-next-line no-plusplus
      for (let i = this.dataHistory.length - 1; i >= 0; i--) {
        if (this.dataHistory[i].new_status !== 'CONCLUÍDO') {
          return i
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hasConcluded = true // Define como true se encontrar um concluído
      }

      return -1 // Retorna -1 se todos estiverem concluídos
    },
  },

  watch: {
    async toggleSwitch() {
      this.loadingStadBy = this.data.status === 'AGUARDANDO ENCERRAMENTO' || this.data.status === 'CONCLUÍDO' || this.data.status === 'AGUARDANDO DP'

      // this.closeModal()

      const body = {
        status: 'STAND-BY',
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao mudar status!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.updatedTable()
        this.closeModal()
        this.loadingStadBy = false
        this.handleUpdatedTable()
      }
    },
  },

  async created() {
    this.userProfile = LocalStorageSlim.get('userProfile', { decrypt: true })

    this.handleChangeResultsWrapperKey()
    this.files = this.data.attachments_shutdowns_rh

    if (this.files?.length > 0) {
      this.isViewingFile = true
    }

    this.dataCancel = this.data

    this.expectedStart = this.formatExpectedStart(this.data.expected_start_date)
    this.hiredDate = this.formatExpectedStart(this.data.hired_date)

    // this.data.expected_start_date = this.dateFormat(this.data.expected_start_date)

    this.data.hired_date = this.data.manager_1_id ? this.dateFormat(this.data.hired_date) : this.data.hired_date

    this.data.hired_person_cpf = this.formattedCpfNumber(this.data.hired_person_cpf)

    // eslint-disable-next-line no-nested-ternary
    this.data.expected_start_date = this.data.manager_1_id ? !this.data.was_person_reproved ? this.data.expected_start_date : '' : this.data.expected_start_date

    if (
      this.data.status === 'AGUARDANDO ENCERRAMENTO'
    || this.data.status === 'CONCLUÍDO'
    || this.data.status === 'AGUARDANDO DP'
    || this.data.status === 'EM ANDAMENTO'
    || this.data.status === 'AGUARDANDO LÍDER'
    ) {
      this.tabs.splice(2, 0, { title: 'Resultados', icon: mdiCheckCircleOutline })
    }

    this.handleGetCurrentDate()
    this.handleGetApplicantData()

    this.handleGetStatusHistory()
    this.isBackToRH()
  },

  methods: {
    formatExpectedStart(data) {
      if (!data) return

      const [date, hours] = data.split(' ')

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
    theresNotification(tab) {
      const alert = this.data.alert || {}
      const isRequisitionForDirector = () => tab.title === 'Requisição' && this.data.status === 'AGUARDANDO RD' && this.profile === 'Diretor' && alert.vote
      const isResultsForHR = () => tab.title === 'Resultados' && this.data.status === 'EM ANDAMENTO' && this.profile === 'Recursos Humanos' && alert.disqualification
      const isResultsForDP = () => tab.title === 'Resultados' && this.data.status === 'AGUARDANDO DP' && this.profile === 'Departamento Pessoal' && alert.disqualification

      const notificationExists = isRequisitionForDirector() || isResultsForHR() || isResultsForDP()

      this.theresNotifications = notificationExists

      return notificationExists
    },

    isBackToRH() {
      const alert = this.data.alert || {}
      const isResultsForHR = () => this.data.status === 'EM ANDAMENTO' && this.profile === 'Recursos Humanos' && alert.disqualification
      const isResultsForDP = () => this.data.status === 'AGUARDANDO DP' && this.profile === 'Departamento Pessoal' && alert.disqualification

      const returnedToRH = isResultsForHR() || isResultsForDP()

      this.returnedToRH = returnedToRH

      return returnedToRH
    },

    async handleSendToLeader() {
      const body = {
        status: 'AGUARDANDO LÍDER',
      }
      this.isSendingToLeader = true
      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          showConfirmButton: false,
          text: 'Requisição enviada.',
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.isSendingToLeader = false
        this.updatedTable()
        this.closeModal()
      }
    },
    handleOpenApprovedRegisterModal() {
      this.isApprovedRegisterModalOpen = true
    },
    async handleGetApplicantData() {
      try {
        const response = await axiosIns.get(`api/v1/personnel_request/employee_request/edit/${this.id}`)
        console.log(response.data.data.creator)

        this.applicantName = response.data.data.creator.name
        this.applicantStore = response.data.data.creator.company.city
        this.applicantSector = response.data.data.creator.occupation_sector.sector.name
        this.radioValue = response.data.data.is_for_acessories ? 'SIM' : 'NÃO'
        this.sectorValueid = response.data.data.sector_id
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao requisitar os dados!',
          showConfirmButton: false,
          text: 'Ocorreu um erro ao buscar as informações do solicitante!',
          timer: 3000,
        })
      }
    },

    async downloadFile(fileId, fileName) {
      this.isLoadingFile = fileId

      const body = { id: fileId }

      await axiosIns
        .post('/api/v1/personnel_request/attachments_shutdowns_rh/view_files', body, { responseType: 'arraybuffer' })
        .then(response => {
          const contentType = response.headers['content-type']
          const file = new File([response.data], fileName, {
            type: contentType,
          })

          saveAs(file)
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isLoadingFile = ''
        })
    },
    handleCloseModalRd() {
      this.closeModal()
    },

    handleUpdatedTable() {
      this.updatedTable()
    },

    handleOpenModalCancel() {
      this.showModalCancel = true
    },
    handleOpenModalDirectorCancel() {
      this.showModalDirectorCancel = true
    },

    getStatusClass(index) {
      // eslint-disable-next-line no-nested-ternary
      return this.hasConcluded ? 'status-concluido' : (index === this.lastNonCompletedIndex ? 'status-atual' : 'status-concluido')
    },

    isLast(item) {
      return this.dataHistory.indexOf(item) === this.dataHistory.length - 1
    },

    async handleGetStatusHistory() {
      try {
        if (!this.data?.id) {
          return
        }

        this.loadingHistory = true

        const infos = await axiosIns.get(`api/v1/personnel_request/employee_request_status_history/edit/${this.data.id}`)

        this.dataHistory = infos.data.data
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao requisitar os dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingHistory = false
      }
    },

    async handleFinishRequest() {
      this.loadingFinish = true

      const body = {
        status: 'CONCLUÍDO',
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Requisição conluída',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingFinish = false
        this.updatedTable()
        this.closeModal()
      }
    },

    handleGetCurrentDate() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const seconds = date.getSeconds()

      const dataFormatada = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

      this.currentDate = dataFormatada
    },

    handleOpenModalSendDP() {
      this.showModalDP = true
    },

    async handleSendDirector() {
      this.loadingSubmitRequestSendDirector = true

      this.handleGetCurrentDate()

      const body = {
        status: this.data.status === 'AGUARDANDO DIRETORIA' ? 'EM ANDAMENTO' : 'AGUARDANDO DIRETORIA',
      }

      if (this.data.status === 'AGUARDANDO DIRETORIA') body.director_approved_at = this.currentDate
      if (this.data.status === 'AGUARDANDO RD') body.manager_approved_at = this.currentDate

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Enviado para diretoria com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSubmitRequestSendDirector = false
        this.updatedTable()
        this.closeModal()
      }
    },
    handleChangeResultsWrapperKey() {
      this.ResultsWrapperKey = Math.random()
    },
    async handleCancelRequest() {
      this.loadingCancel = true

      const body = {
        status: 'CANCELADO',
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Requisição recusada com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingCancel = false
        this.updatedTable()
        this.closeModal()
      }
    },
  },
}
</script>
<style scoped>
.approvedRegister{
  cursor: pointer;
  gap: 10px;
  border: 1px;
  border-style: dashed;
  width: 100%;
  height: 41px;
  border-radius: 5px;
}
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 25px;
}

.container-center{
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.validations{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
}

.validations span{
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 8px;
}

.infos{
  display: flex;
  margin-top: 10px;
}

.image{
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag{
  background: #56CA001A;
  color: #56CA00;
  width: 85px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.infos-text{
  display: flex;
  align-items: center;
}

.progress-container{
  position: relative;
  width: 100%;
  padding: 25px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;
}

.progress-container-loading{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

}

.progress-container span{
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 12px;
  font-size: 12px;
}

.item-time-line{
  border-left: 1px dashed #8C8C8C;
  padding: 10px;
  margin-left: 9px;

  p{
    font-size: 12px;
    margin-left: 10px;
  }
}

.no-border {
  border-left: 1px solid transparent;
}

.item-time-line-first{
  border-left: 1px dashed #8C8C8C;
  padding: 10px;
  margin-left: 9px;
  margin-bottom: -14px;

  p{
    font-size: 12px;
     margin-left: 10px;
  }
}

.container-item{
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  & + &{
    margin-top: 0px;

  }
}

.item-time-line-waiting{
  padding: 10px;
  margin-left: 9px;

  p{
    font-size: 12px;
  }
}

.container-icon-waiting{
  display: flex;

  small{
    font-size: 12px;
    margin-left: 8px;
    color: #FFB270;
  }
}

.container-icon{
  display: flex;

  small{
    font-size: 12px;
    margin-left: 8px;
  }

  .status-atual {
    color: #FFB270;
  }

  .status-concluido {
    color: #39CE2F;
  }
}

.stand-by{
  display: flex;
  align-items: center;
  margin-left: 4px;

  p{
    margin-top: 12px;
  }
}

.footer{
  display: flex;
  justify-content: space-between;
}

.inputs-approved{
  margin-top: 20px;
}

.wrapper-inputs{
  display: flex;
  gap: 10px;
}

.actions-finish{
  display: flex;
  align-items: center;
  justify-content: center;
}

.infos-aproved{
  margin-left: 10px;

  p{
    margin-bottom: 5px;
  }
}

.center-div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs-just{
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
