<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Cadastrar aprovado</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalSendRD"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <Separator
        label="informações do aprovado"
        class="my-8"
      />

      <v-form ref="form">
        <v-text-field
          v-model="candidate_name"
          class="mt-6"
          :rules="[rules.required]"
          label="Nome do candidato"
          outlined
          dense
        />

        <div class="line mt-0">
          <v-text-field
            v-model="candidate_cpf"
            class=""
            :rules="[rules.required]"
            label="CPF"
            type="number"
            outlined
            dense
          />
          <v-dialog
            ref="dialogDateTime"
            v-model="showCalendar"
            :return-value.sync="inconsistencyDate"
            width="400px"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedInconsistencyDate"
                :append-icon="icons.mdiCalendar"
                :rules="[rules.required]"
                label="Previsão de Admissão"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="inconsistencyDate"
              width="400px"
              locale="pt-BR"
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="showCalendar = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialogDateTime.save(inconsistencyDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>

        <v-file-input
          ref="fileInput"
          v-model="files"
          :prepend-inner-icon="icons.mdiPaperclip"
          prepend-icon=""
          label="Inserir documentos"
          outlined
          multiple
          chips
          dense
        />

        <span
          class="container-caption"
        >
          * O tamanho do arquivo não pode exceder o limite máximo de 10MB.
        </span>

        <Separator
          label="Outras informações"
          class="my-6"
        />

        <v-textarea
          v-model="rh_observations"
          class="mt-6"
          label="Parecer do RH"
          :rules="[rules.required]"
          outlined
        />

        <div class="container-center">
          <v-btn
            color="info"
            :disabled="loadingSendDP"
            @click="handleSendDP"
          >
            <div
              v-if="!loadingSendDP"
              class="d-flex align-center"
              style="gap: 5px;"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>CADASTRAR</span>
            </div>

            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>
<script>

import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import {
  mdiCalendar,
  mdiClose, mdiPaperclip,
  mdiPlus,
} from '@mdi/js'

export default {
  components: {
    Separator,
  },

  mixins: [formatters, utilsMixin, messages],

  props: {
    data: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      files: [],
      inconsistencyDate: '',
      showCalendar: false,
      loadingSendDP: false,
      icons: {
        mdiClose,
        mdiCalendar,
        mdiPaperclip,
        mdiPlus,
      },
      listClass: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
      listLevels: ['1', '2', '3', '4', '5', '6', '7', '8'],
      salary: '',
      className: '',
      level: '',
      atr: '',
      comments: '',
      candidate_name: '',
      candidate_cpf: '',
      rh_observations: '',
      expectedStartDate: '',
    }
  },

  computed: {
    computedInconsistencyDate: {
      get() {
        return this.formatDate(this.inconsistencyDate)
      },

      set() {},
    },
  },

  created() {
  },

  methods: {
    handleCloseModalSendRD() {
      this.$emit('closeModalRdDp')
    },

    async handleSendDP() {
      const isValid = this.$refs.form.validate()

      if (!isValid) {
        return
      }

      this.loadingSendDP = true

      const body = {
        status: 'Classificado',
        name: this.candidate_name,
        rh_observations: this.rh_observations,
        cpf: this.candidate_cpf,
        expected_start_date: this.inconsistencyDate,
        employee_request_id: this.data.id,
      }

      try {
        await axiosIns.post('api/v1/personnel_request/classification_history/store', body).then(() => {
          if (this.files.length === 0) {
            this.$swal({
              icon: 'success',
              title: 'Requisição enviada!',
              text: 'A requisição foi enviada para o departamento Pessoal!',
              showConfirmButton: false,
              timer: 3000,
            })
          }

          if (this.files.length > 0) {
            this.sendFiles('/api/v1/personnel_request/attachments_shutdowns_rh/submit_files', this.files, { id: this.data.id, candidate_cpf: this.candidate_cpf })
          }
        })
        this.$emit('updateResults')
        this.$emit('changeResultsWrapperKey')
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          showConfirmButton: false,
          text: 'Candidato cadastrado.',
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSendDP = false

        this.updatedTable()
        this.handleCloseModalSendRD()
      }
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container-center{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.container-caption {
  display: flex;
  margin-top: -20px ;
  margin-bottom: 20px;
  font-size: 12px ;
  padding-left: 4px;
}
</style>
