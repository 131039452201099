<template>
  <v-skeleton-loader
    v-if="skeleton"
    type="article, actions"
  ></v-skeleton-loader>
  <v-card
    v-else
    class="px-9 py-9"
  >
    <div class="head-modal">
      <div></div>

      <h3 style="font-weight: 600; font-size: 18px;">
        Requisição de Pessoal
      </h3>

      <v-icon
        medium
        class="me-2"
        @click="handleCloseModal"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>
    <div class="py-4">
      <h3 style="font-weight: 500; font-size: 14px; text-align: center;">
        👋 Olá, para requisitar, basta preencher os campos abaixo!
      </h3>
    </div>
    <Separator
      label="Justificativa"
      class="mt-3"
    />

    <div class="line">
      <v-text-field
        label="Tipo de Contrato"
        :value="typeContractValue"
        outlined
        dense
        readonly
      />

      <v-text-field
        :value="reasonRequestValue"
        label="Motivo da Requisição"
        outlined
        dense
        readonly
      />
    </div>

    <v-text-field
      v-if="reasonRequestValue === 'SUBSTITUIÇÃO'"
      :value="replacedEmployeeValue.name"
      label="Funcionário Substituído"
      item-text="name"
      item-value="id"
      outlined
      dense
      readonly
      :disabled="reasonRequestValue !== 'Substituição'"
    />

    <v-textarea
      :value="reasonDescriptionValue"
      label="Descrição do motivo"
      outlined
      readonly
    />
    <Separator
      label="Dados da vaga"
      class=" mt-0 mb-6"
    />

    <v-text-field
      v-model="functionValue.name"
      class="mt-5"
      label="Função"
      item-text="name"
      item-value="id"
      outlined
      dense
      disabled
    />

    <v-text-field
      v-model="sectorValue.name"
      class=""
      label="Setor"
      item-text="name"
      item-value="id"
      outlined
      dense
      disabled
    />

    <div
      v-if="sectorValueid === 'ff5f9668-d5b7-42f5-ae2b-5fe34854a2a0' || sectorValueid === 'd911e3ed-a989-479e-a8e9-36debec9df70' || sectorValueid === '6ce1e394-daf0-4294-827c-4d05eb4b4f91' || sectorValueid === '38b9b784-053f-4587-b8c6-346baad569dd' || sectorValueid === 'efabfa1d-da9a-482d-baa5-7af8500f5101'"
      style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 25px; height: 30px;"
    >
      <div>
        <p>A função terá a responsabilidade de vender acessórios?</p>

        <v-radio-group
          v-model="radioValue"
          row
          disabled
        >
          <v-radio
            label="Sim"
            value="SIM"
            color="#9C43FA"
          />

          <v-radio
            label="Não"
            value="NÃO"
            color="#9C43FA"
          />
        </v-radio-group>
      </div>
    </div>

    <div class="line mt-0">
      <v-text-field
        v-model="ageValue"
        style="width: 62px;"
        label="Idade"
        outlined
        dense
        disabled
      />

      <v-text-field
        v-model="genderValue"
        class=""
        label="Sexo"
        outlined
        dense
      />
      <v-text-field
        v-model="educationLevelValue"
        class=""
        label="Nível de escolaridade"
        outlined
        dense
      />
    </div>

    <v-textarea
      v-model="requirementsVacancyValue"
      label="Requisitos da Vaga"
      outlined
    />

    <v-textarea
      v-model="mainActivitiesValue"
      label="Principais Atividades"
      outlined
    />

    <div
      v-if="level === 'nivel_2'"
      style="width: 100%; display: flex; align-items: center; justify-content: center;"
    >
      <v-btn
        color="info"
        @click="isModalLeaderApproval = true"
      >
        VALIDAR
      </v-btn>
    </div>
    <v-dialog
      v-model="isModalLeaderApproval"
      width="600"
      persistent
    >
      <ModalLeaderApproval
        :key="data.id"
        :data="data"
        @close="isModalLeaderApproval = false"
        @closeAll="this.$emit('close')"
        @updateTable="handleUpdateTable"
      >
      </ModalLeaderApproval>
    </v-dialog>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import { mdiClose } from '@mdi/js'
import ModalLeaderApproval from './ModalLeaderApproval.vue'

export default {
  name: 'ModalWaitingApproval',
  components: {
    ModalLeaderApproval,
    Separator,
  },
  props: {
    data: {
      type: [String, Object],
      required: true,
    },
    profile: { type: String, default: '' },
    level: { type: String, default: '' },
  },
  data() {
    return {
      skeleton: true,
      isModalLeaderApproval: false,

      typeContractValue: '',
      reasonRequestValue: '',
      replacedEmployeeValue: {},
      reasonDescriptionValue: '',
      functionValue: {},
      sectorValue: '',
      sectorValueid: '',
      ageValue: '',
      educationLevelValue: '',
      genderValue: '',
      requirementsVacancyValue: '',
      mainActivitiesValue: '',

      icons: {
        mdiClose,
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleUpdateTable() {
      this.$emit('updatedTable')
    },
    async getData() {
      try {
        const response = await axiosIns.get(`api/v1/personnel_request/employee_request/edit/${this.data.id}`)

        this.typeContractValue = response.data.data.contract_types
        this.reasonRequestValue = response.data.data.request_reason
        this.replacedEmployeeValue = { name: response.data.data?.substitute_user?.name, id: response.data.data?.substitute_user?.id }
        this.reasonDescriptionValue = response.data.data.request_reason_description
        this.functionValue = { name: response.data.data?.occupation?.name, id: response.data.data?.occupation?.id }
        this.sectorValue = { name: response.data.data?.sector?.name, id: response.data.data?.sector?.id }
        this.ageValue = response.data.data.age
        this.educationLevelValue = response.data.data.education_level
        this.genderValue = response.data.data.sex
        this.requirementsVacancyValue = response.data.data.vacancy_requirements
        this.mainActivitiesValue = response.data.data.main_activities
        this.sectorValueid = response.data.data.sector_id
        this.radioValue = response.data.data.is_for_acessories ? 'SIM' : 'NÃO'
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          showConfirmButton: false,
          text: 'Erro ao buscar os dados na API!',
          timer: 3000,
        })
      } finally {
        this.skeleton = false
      }
    },
    handleCloseModal() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.head-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.line {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
}
</style>
