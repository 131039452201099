import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.skeleton)?_c(VSkeletonLoader,{attrs:{"type":"article, actions"}}):_c(VCard,[_c('div',{staticClass:"head-modal"},[_c('div'),_c('h3',{staticStyle:{"font-weight":"600","font-size":"18px"}},[_vm._v(" Desclassificar candidato ")]),_c(VIcon,{staticClass:"me-2 pr-3",attrs:{"medium":""},on:{"click":_vm.closeDisqualifyModal}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c('h3',{staticClass:"mt-4 text-center mx-10 mb-7",staticStyle:{"font-size":"14px","font-weight":"400"}},[_vm._v(" 👋 Olá! Antes de realizar a desclassificação, por favor, descreva o motivo abaixo ")]),_c(VForm,{ref:"form",staticClass:"mx-5"},[_c(VTextarea,{attrs:{"outlined":"","label":"Descrição do motivo","rules":[_vm.rules.required]},model:{value:(_vm.reasonToDesqualify),callback:function ($$v) {_vm.reasonToDesqualify=$$v},expression:"reasonToDesqualify"}}),_c('div',{staticClass:"d-flex align-center justify-center pb-6"},[_c(VBtn,{attrs:{"color":"error","outlined":""},on:{"click":_vm.handleDisqualifyApproved}},[_vm._v(" DESCLASSIFICAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }