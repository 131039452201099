import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.leadersData.length > 0)?_c('div',[_c('Separator',{staticClass:"mt-6",attrs:{"label":"Líderes que validaram a abertura do processo"}}),_c('fieldset',{staticClass:"my-6",staticStyle:{"border-radius":"5px","border-width":"1px","border-color":"#5C5471"}},[_c('legend',{staticClass:"px-4",staticStyle:{"margin-left":"10px"}},[_vm._v(" Líderes ")]),_c(VCard,{staticClass:"p-4"},_vm._l((_vm.failedOrApproved),function(item){return _c('div',{key:item.user_name,staticClass:"pb-3 isNotExpanded",class:{'isExpanded': item.isExpand}},[(item.vote === 'Reprovado' || item.vote === 'Aprovado')?_c('div',{staticClass:"d-flex align-center justify-space-between mx-0 pt-2 pb-2",class:{'isExpandedInside': item.isExpand, 'isNotExpandedInside': !item.isExpand}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.leadersVotesDiverge(item))?_c(VTooltip,{attrs:{"color":"orange","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"pb-6",staticStyle:{"position":"absolute","z-index":"10","margin-left":"-15px"},attrs:{"color":"orange","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiExclamationThick)+" ")])]}}],null,true)},[_c('span',[_vm._v("Importante")])]):_vm._e(),_c(VAvatar,{staticClass:"v-avatar-light-bg success--text",attrs:{"size":"30","color":"error"}},[(item.user_image)?_c(VImg,{attrs:{"src":item.base64}}):_c('span',{staticClass:"v-avatar-light-bg success--text"},[_vm._v(" "+_vm._s(_vm.getInitials(item))+" ")])],1),_c('div',{staticClass:"ml-2 d-flex align-start",staticStyle:{"gap":"10px"}},[_c('div',[_c('h3',{staticStyle:{"font-weight":"500","font-size":"12px"}},[_vm._v(" "+_vm._s(item.user_name)+" ")]),_c('div',{staticClass:"d-flex align-center mt-1",staticStyle:{"gap":"9px"}},[_c(VIcon,{attrs:{"size":"16"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarBlankOutline)+" ")]),_c('h3',{staticStyle:{"font-weight":"500","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.dateFormat(item.updated_at))+" ")])],1)]),_c('div',{staticClass:"d-flex align-center justify-center",class:{'approved': item.vote === 'Aprovado', 'failed': item.vote === 'Reprovado'},staticStyle:{"padding":"3px 10px","border-radius":"50px"}},[_c('span',{staticStyle:{"font-weight":"600","font-size":"12px"}},[_vm._v(_vm._s(item.vote))])])])],1),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"10px"}},[(item.vote === 'Reprovado')?_c(VTooltip,{attrs:{"top":"","color":"gray"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"gray","icon":""},on:{"click":function($event){item.isExpand = !item.isExpand}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"gray"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChatProcessingOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Motivo")])]):_vm._e()],1)]):_vm._e(),(item.vote === 'Reprovado' && item.isExpand)?_c('div',{staticClass:" mx-5"},[_c('h3',{staticClass:"pb-5",staticStyle:{"font-weight":"500","font-size":"14px"}},[_vm._v(" Motivo da reprovação: ")]),_c('h3',{staticStyle:{"font-weight":"500","font-size":"14px"}},[_vm._v(" "+_vm._s(item.description)+" ")])]):_vm._e()])}),0)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }