<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Contratar candidato</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalSendRD"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div
        class="py-4"
        style="text-align: center;"
      >
        <span>👋 Olá, por favor, insira a data de admissão abaixo:</span>
      </div>
      <Separator
        label="Informações de admissão"
        class="my-6"
      />
      <div class="d-flex content-center justify-center">
        <div class="line mt-4 mb-2">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="400px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                label="Data de admissão"
                :append-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                dense
                outlined
                @blur="date = parseDate(dateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <!-- <v-dialog
            ref="dialogDateTime"
            v-model="showCalendar"
            :return-value.sync="inconsistencyDate"
            width="400px"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedInconsistencyDate"
                :append-icon="icons.mdiCalendar"
                :rules="[rules.required]"
                label="Data de Admissão"
                readonly
                outlined
                style=""
                dense
                v-bind="attrs"
                :disabled="toggleExam"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="inconsistencyDate"
              width="400px"
              locale="pt-BR"
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="showCalendar = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialogDateTime.save(inconsistencyDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog> -->
        </div>
      </div>

      <div class="container-center">
        <v-btn
          color="success"
          class="ml-4"
          @click="handleSendRH"
        >
          <span v-if="!loadingSendDP">CONTRATAR</span>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>

import Separator from '@/components/Separator.vue'

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiCalendar, mdiClose } from '@mdi/js'

export default {
  components: {
    Separator,
  },

  mixins: [formatters, utilsMixin],

  props: {
    data: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      toggleExam: false,
      toggleSurrendered: false,
      inconsistencyDate: '',
      showCalendar: false,
      loadingSendDP: false,
      menu: false,
      date: '',
      dateFormatted: '',
      icons: {
        mdiClose,
        mdiCalendar,
      },
      listClass: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
      listLevels: ['1', '2', '3', '4', '5', '6', '7', '8'],
      salary: '',
      className: '',
      level: '',
      atr: '',
      comments: '',
      hiredPersonName: '',
      approved_name: '',
      hiredPersonCpf: '',
      expectedStartDate: '',
    }
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    computedInconsistencyDate: {
      get() {
        return (this.inconsistencyDate)
      },

      set() {},
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },

  created() {
    this.handleGetClassified()
  },

  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')

      return `${year}-${month}-${day}`
    },
    handleCloseModalSendRD() {
      this.closeModal()
    },
    async handleGetClassified() {
      try {
        const data = await axiosIns.get(`api/v1/personnel_request/classification_history/edit/${this.data.id}`)
        this.approved_name = data.data.data[data.data.data.length - 1].name
        console.log(data.data.data[data.data.data.length - 1].name)
      } catch (error) {
        console.log(error)
      }
    },
    async handleSendRH() {
      this.loadingSendDP = true

      console.log(this.date)
      const body = {
        status: 'CONCLUÍDO',
        hired_date: this.date,
        hired_person_name: this.approved_name,
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Candidato contratado. Processo concluído',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSendDP = false
        this.updatedTable()
        this.$emit('closeRD')
        this.closeModal()
      }
    },

  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container-center{
  display: flex;
  justify-content: center;
}

.line{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 230px;
  margin-top: 30px;
}

.stand-by{
  display: flex;
  align-items: center;

}
</style>
