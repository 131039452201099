<template>
  <div
    :key="resultsKey"
    class="d-flex flex-column"
    style="gap: 10px;"
  >
    <v-expansion-panels
      v-for="(result, index) in resultsFormatted"
      v-show="!!result"
      :key="result.id"
      v-model="result.isExpanded"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="d-flex align-center justify-between w-100"
          :style="headerStyle"
        >
          <div
            class="d-flex align-center"
            style="gap: 10px;"
          >
            <v-tooltip
              v-if="theresNotificationsAndIsNewest(index)"
              color="orange"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="orange"
                  size="20"
                  class="pb-7"
                  style="position: absolute; z-index: 1; margin-left: -14px;"
                  v-on="on"
                >
                  {{ icons.mdiExclamationThick }}
                </v-icon>
              </template>
              <span>Importante</span>
            </v-tooltip>
            <div class="image">
              <div
                v-if="result.status === 'Classificado'"
                style="width: 45px; height: 45px"
              >
                <v-img
                  src="../../../../../../assets/approved-avatar.svg"
                />
              </div>
              <div
                v-else
                style="width: 45px; height: 45px"
              >
                <v-img src="../../../../../../assets/disapproved-avatar.svg" />
              </div>
            </div>
            <div
              class="d-flex flex-column"
              style="gap: 5px;"
            >
              <div
                class="d-flex align-center"
                style="gap: 10px;"
              >
                <h3 style="font-weight: 500; font-size: 13px">
                  {{ result.name.toUpperCase() }}
                </h3>
              </div>
              <div
                style="padding: 5px 10px; border-radius: 50px;  width: fit-content;"
                class="d-flex align-center justify-center"
                :class="{'classified': result.status === 'Classificado', 'disqualified': result.status === 'Desclassificado'}"
              >
                <span
                  style="font-weight: 600; font-size: 12px;"
                >{{ result.status }}</span>
              </div>
            </div>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content
          :style="headerStyle"
        >
          <div
            v-if="(profile === 'Recursos Humanos' || profile === 'Departamento Pessoal') && (result.status === 'Classificado' && data.status !== 'CONCLUÍDO') && data.status !== 'AGUARDANDO LÍDER'"
            class="w-100 d-flex align-center justify-end"
          >
            <v-tooltip
              v-if="!isEditable"
              top
              color="info"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="info"
                  v-bind="attrs"
                  @click="handleActiveInputs"
                  v-on="on"
                >
                  <v-icon
                    size="25"
                    color="info"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </template>

              <span>Editar</span>
            </v-tooltip>
            <v-tooltip
              v-else
              top
              color="success"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  v-bind="attrs"
                  @click="handleSaveData(result)"
                  v-on="on"
                >
                  <v-icon
                    size="25"
                    color="success"
                  >
                    {{ icons.mdiContentSave }}
                  </v-icon>
                </v-btn>
              </template>

              <span>Salvar</span>
            </v-tooltip>
          </div>
          <v-form ref="form">
            <v-text-field
              v-model="result.name"
              class="mt-6"
              :rules="[rules.required]"
              :disabled="!isEditable"
              label="Nome do candidato"
              outlined
              dense
            />

            <div
              class="mt-0 d-flex"
              style="gap: 20px;"
            >
              <v-text-field
                v-model="result.cpf"
                :rules="[rules.required]"
                :disabled="!isEditable"
                label="CPF"
                outlined
                dense
              />
              <v-dialog
                ref="dialogDateTime"
                v-model="showCalendar"
                :return-value.sync="result.inconsistencyDate"
                width="400px"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="result.inconsistencyDateFormatted"
                    :append-icon="icons.mdiCalendar"
                    :rules="[rules.required]"
                    :disabled="isFieldDisabled"
                    label="Previsão de Admissão"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="result.inconsistencyDate"
                  width="400px"
                  locale="pt-BR"
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="showCalendar = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="handleSaveDate(result)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>

            <v-file-input
              v-if="result.status === 'Classificado'"
              ref="fileInput"
              v-model="result.files"
              :prepend-inner-icon="icons.mdiPaperclip"
              prepend-icon=""
              :disabled="!isEditable"
              label="Inserir documentos"
              outlined
              multiple
              chips
              dense
            />
            <div
              v-if="result.status === 'Classificado'"
              class="mb-4"
            >
              <span style="font-size: 14px">
                * O tamanho do arquivo não pode exceder o limite máximo de 10MB.
              </span>
            </div>
            <fieldset
              v-if="!isLoadingGetFile && result.filesToDownload.length > 0"
              class="py-3 px-3"
              style="border-radius: 5px"
            >
              <legend class="px-2">
                Documentos
              </legend>
              <div
                class="d-flex flex-column"
                style="gap: 5px;"
              >
                <div
                  v-for="file in result.filesToDownload"
                  :key="file.id"
                >
                  <v-chip
                    size="small"
                    color="white"
                    close
                    outlined
                    dense
                    @click:close="onChipClose(file, result)"
                    @click="downloadFile(file.id, file.name)"
                  >
                    <span v-if="file.id !== isLoadingFile">{{ file.name }}</span>

                    <v-progress-circular
                      v-else
                      :key="file.id"
                      :size="25"
                      color="white"
                      indeterminate
                    />
                  </v-chip>

                  <v-progress-circular
                    v-if="isLoadingGetFile"
                    indeterminate
                    color="primary"
                  />
                </div>
              </div>
            </fieldset>

            <Separator
              dark-background-color="#3B355A"
              light-background-color="#fcfcfc"
              :disabled="!isEditable"
              label="Outras informações"
              class="my-6"
            />

            <v-textarea
              v-model="result.rh_observations"
              class="mt-6"
              disabled
              label="Parecer do RH"
              :rules="[rules.required]"
              outlined
            />
            <v-textarea
              v-if="result.status === 'Desclassificado'"
              v-model="result.reason_for_disqualification"
              disabled
              label="Motivo da Desclassificação"
              :rules="[rules.required]"
              outlined
            />
            <v-textarea
              v-if="data.status === 'AGUARDANDO DP' && result.status === 'Classificado'"
              v-model="data.leader_observations"
              disabled
              label="Parecer do líder"
              :rules="[rules.required]"
              outlined
            />

            <div
              v-if="result.status === 'Classificado' && data.status !== 'CONCLUÍDO'"
              style="width: 100%; justify-content: space-between;"
              class="d-flex align-center "
            >
              <v-btn
                v-if="profile === 'Departamento Pessoal' && data.status === 'AGUARDANDO DP'"
                color="error"
                outlined
                :disabled="isSending"
                @click="handleDisqualify"
              >
                <div
                  v-if="!loadingSendDP"
                  class="d-flex align-center"
                  style="gap: 5px;"
                >
                  <span>DESCLASSIFICAR</span>
                </div>

                <v-progress-circular
                  v-else
                  color="white"
                  indeterminate
                >
                </v-progress-circular>
                <v-dialog
                  v-model="showDisqualifyModal"
                  width="600px"
                >
                  <ModalDisqualifyApproved
                    :data="result"
                    @close="showDisqualifyModal = false"
                    @updateResults="handleUpdateResults"
                    @updatedTable="updateMainTable"
                    @atualiza="handleAtualiza"
                  ></ModalDisqualifyApproved>
                </v-dialog>
              </v-btn>
              <v-btn
                v-if="profile === 'Departamento Pessoal' && data.status === 'AGUARDANDO DP'"
                color="success"

                :disabled="isSending"
                @click="handleOpenModalRH"
              >
                <div
                  v-if="!loadingSendDP"
                  class="d-flex align-center"
                  style="gap: 5px;"
                >
                  <span>CONTRATAR</span>
                </div>

                <v-progress-circular
                  v-else
                  color="white"
                  indeterminate
                >
                </v-progress-circular>
              </v-btn>
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-btn
      v-if="profile === 'Recursos Humanos' && data.status !== 'CONCLUÍDO' && data.status !== 'STAND-BY'"
      class="d-flex align-center justify-center approvedRegister"
      :disabled="resultsFormatted.length > 0 && haveClassified === false"
      style=" border-style: dashed;"
      outlined
      @click="OpenModalSendDP"
    >
      Cadastrar aprovado
      <v-icon>{{ icons.mdiPlus }}</v-icon>
    </v-btn>
    <div v-if="(!(!!resultsFormatted) || resultsFormatted.length === 0)">
      <NoDataInterfaceEditable
        title="Nenhum aprovado cadastrado no momento!"
        subtitle="Aguarde o RH cadastrar."
      />
    </div>
  </div>
</template>

<script>
import NoDataInterfaceEditable from '@/components/charts/NoDataInterfaceEditable.vue'
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import {
  mdiCalendar,
  mdiContentSave,
  mdiExclamationThick,
  mdiPaperclip,
  mdiPencil, mdiPlus, mdiTrashCan,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import ModalDisqualifyApproved from '../ModalDisqualifyApproved.vue'

export default {
  name: 'ResultsWrapper',
  components: {
    Separator,
    ModalDisqualifyApproved,
    NoDataInterfaceEditable,
  },
  mixins: [formatters, utilsMixin, messages],
  props: {
    data: {
      type: [String, Object],
      required: true,
    },
    profile: { type: String, default: '' },
    theresNotifications: { type: Boolean, default: false },
  },
  data() {
    return {
      files: [],
      resultsData: {},
      filesToDownload: [],
      isLoadingFile: '',
      resultsFormatted: [],
      inconsistencyDate: '',
      haveClassified: false,
      showCalendar: false,
      loadingSendDP: false,
      isEditable: false,
      isFileLoading: false,
      isLoadingGetFile: false,
      isViewingFile: false,
      showDisqualifyModal: false,
      resultsKey: null,
      isSending: false,
      icons: {
        mdiPlus,
        mdiPencil,
        mdiCalendar,
        mdiPaperclip,
        mdiContentSave,
        mdiTrashCan,
        mdiExclamationThick,
      },
      panelState: false,
    }
  },
  computed: {
    isFieldDisabled() {
      if (this.profile === 'Recursos Humanos') {
        return !this.isEditable
      }

      return true
    },
    nameToUppercase(name) {
      return name.toUpperCase()
    },
    headerStyle() {
      return this.$vuetify.theme.dark
        ? 'background-color: #3b355a;'
        : 'background-color: #fcfcfc;'
    },
    computedViewFileContainer() {
      const isFileLoading = !this.isLoadingGetFile
      const isFileViewing = this.isViewingFile

      return isFileLoading && isFileViewing
    },
  },
  watch: {
    resultsFormatted: {
      handler(newValue) {
        console.log(newValue)

        if (newValue.length === 0 || (this.haveClassified)) {
          this.$emit('arrayStatus', true)
        } else {
          this.$emit('arrayStatus', false)
        }
      },
    },
  },
  created() {
    this.filesToDownload = this.resultsData?.attachments_shutdowns_rh

    // if (this.filesToDownload?.length > 0) {
    //   this.isViewingFile = true
    // }
    this.handleGetRequestData()
  },

  methods: {
    theresNotificationsAndIsNewest(index) {
      return this.theresNotifications && this.resultsFormatted.length - 1 === index && this.theresNotifications
    },
    handleAtualiza() {
      this.$emit('AtualizaAi')
    },
    updateMainTable() {
      this.$emit('updatedTable')
    },
    handleSaveDate(result) {
      this.showCalendar = false

      result.inconsistencyDateFormatted = this.formatDate(result.inconsistencyDate)
    },
    async downloadFile(fileId, fileName) {
      this.isLoadingFile = fileId

      const body = { id: fileId }

      await axiosIns
        .post('/api/v1/personnel_request/attachments_shutdowns_rh/view_files', body, { responseType: 'arraybuffer' })
        .then(response => {
          const contentType = response.headers['content-type']
          const file = new File([response.data], fileName, {
            type: contentType,
          })

          saveAs(file)
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isLoadingFile = ''
        })
    },
    formatDate(date) {
      if (!date) return ''
      const [FullDate] = date.split(' ')

      const [year, month, day] = FullDate.split('-')

      return `${day}/${month}/${year}`
    },
    parseDateToBackend(data) {
      const [day, month, year] = data.split('/')

      return `${year}-${month}-${day}`
    },
    parseDate(date) {
      if (!date) return ''
      const [day, month, year] = date.split('/')

      return `${year}-${month}-${day}`
    },
    parseDateStart(date) {
      if (!date) return ''
      const [dataReal] = date.split(' ')
      const [day, month, year] = dataReal.split('/')

      return `${day}-${month}-${year}`
    },

    OpenModalSendDP() {
      this.$emit('openSendDpModal')
    },
    handleDisqualify() {
      this.showDisqualifyModal = true
    },
    handleOpenModalRH() {
      this.$emit('showModalRH')
    },
    handleActiveInputs() {
      this.isEditable = !this.isEditable
    },
    async handleGetRequestData() {
      this.isLoadingGetFile = true
      try {
        const response = await axiosIns.get(`/api/v1/personnel_request/classification_history/edit/${this.data.id}`)

        this.resultsData = response?.data?.data

        this.resultsFormatted = this.resultsData.map(item => ({
          ...item,
          isExpanded: item.status === 'Classificado' ? 0 : undefined,
          inconsistencyDate: this.parseDateStart(item.expected_start_date),
          inconsistencyDateFormatted: this.formatDate(item.expected_start_date),
          filesToDownload: item.attachments.employee_request.attachments_shutdowns_rh || [],
          isViewingFile: item.filesToDownload > 0,
          files: [],
        }))

        this.haveClassified = !this.resultsFormatted.some(item => item.status === 'Classificado')

        console.log(this.resultsData)
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao buscar os dados!',
          showConfirmButton: false,
          timer: 3000,
        })
      } finally {
        this.isLoadingGetFile = false
      }
    },
    async handleUpdateResults() {
      try {
        await this.handleGetRequestData()
        this.$emit('updateMainTable')
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao buscar os dados!',
          showConfirmButton: false,
          timer: 3000,
        })
      } finally {
        this.resultsKey = Math.random()
      }
    },
    async onChipClose(file, result) {
      if (this.profile !== 'Recursos Humanos') {
        this.$swal({
          icon: 'info',
          title: 'Atenção!',
          text: 'Você não tem permissão para excluir arquivo!',
          showConfirmButton: false,
          timer: 5000,
        })

        return
      }
      if (result.status !== 'Classificado') {
        this.$swal({
          icon: 'info',
          title: 'Atenção!',
          text: 'Você não pode excluir o arquivo de um desclassificado!',
          showConfirmButton: false,
          timer: 5000,
        })

        return
      }
      try {
        await axiosIns.delete(`/api/v1/personnel_request/attachments_shutdowns_rh/destroy/${file.id}`)
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Arquivo deletado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao deletar o arquivo!',
          showConfirmButton: false,
          timer: 3000,
        })
      } finally {
        this.handleUpdateResults()
      }
    },
    async handleSaveData(result) {
      const body = {
        name: result.name,
        cpf: result.cpf,
        expected_start_date: this.parseDateToBackend(result.inconsistencyDateFormatted),
        rh_observations: result.rh_observations,
      }
      try {
        await axiosIns.put(`api/v1/personnel_request/classification_history/update/${result.id}`, body)
        if (result.files.length > 0) {
          await this.sendFiles('/api/v1/personnel_request/attachments_shutdowns_rh/submit_files', result.files, { id: result.employee_request_id, candidate_cpf: result.cpf })
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro buscar dados!',
          showConfirmButton: false,
          timer: 3000,
        })
      } finally {
        this.handleUpdateResults()
        this.isEditable = false
      }
    },
  },
}

</script>

<style scoped>
.classified{
  background-color: #56CA001A;
  color: #56CA00;

}
.disqualified{
  background-color: #FF4C511A;
  color: #FF4C51;

}
</style>
